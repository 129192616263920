import { useState, useCallback, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { Button, Snackbar, Grid, Box, Card, CardContent, Typography, Divider } from '@material-ui/core';
import InputText from '../../components/UI/InputText';
import InputLabel from '../../components/UI/InputLabel';

import FriendlyMatchTable, {FriendlyMatchTableRow} from '../../components/admin/match/FriendlyMatchTable';

const AdminMatchPage = () => {
    const [config, setConfig] = useState({
        contestant1: "",
        contestant2: ""
    });
    const [matches, setMatches] = useState([]);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);

    const onError = (message) => {
        setErrorMessage(message);
        setErrorOpen(true);
    }

    const onSuccess = () => {
        setSuccessOpen(true);
    }

    const getMatches = useCallback(async () => {
        let matches = await fetch(`/api/admin/friendly-matches`, {
            credentials: 'include'
        }).then(res => res.json());
        return matches;
    }, []);

    const reload = useCallback(async () => {
        getMatches().then(matchlist => {
            if(!matchlist) return;
            matchlist.sort((a, b) => {
                return b.id - a.id;
            });
            setMatches(matchlist);
        });
    }, [getMatches])

    useEffect(() => {
        reload();
    }, [reload]);

    const addMatch = async () => {
        const fetchOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                contestant1: config.contestant1,
                contestant2: config.contestant2
            }),
            credentials: 'include'
        }
        const response = await fetch(`/api/admin/add-match`, fetchOptions);
        if(response.status === 401) {
            onError("Unauthorized");
            return;
        } else {
            const res = await response.json();
            if (res.status === "FAILED") {
                onError(res.body.error);
            } else {
                onSuccess();
                reload();
            }
        }
    }

    const onAdd = () => {
        addMatch();
    }

    const onChange = (event) => {
        setConfig(prev => {
            const tmp = {...prev};
            tmp[event.target.name] = event.target.value;
            return tmp;
        });
    }

    return (
        <>
            <Card elevation={6}>
                <CardContent>
                    <Grid container spacing={3} direction='column'>
                        <Grid item xs={12}>
                            <Typography variant='h6'> Friendly Matches </Typography>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Box mb={2}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <InputLabel>Contestant 1</InputLabel>
                                        <InputText value={config.contestant1} name="contestant1" fullWidth onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <InputLabel>Contestant 2</InputLabel>
                                        <InputText value={config.contestant2} name="contestant2" fullWidth onChange={onChange} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Button variant="contained" color="primary" onClick={onAdd}>Add Friendly Match</Button>
                        </Grid>
                        <Grid item>
                            <Box mt={2}>
                                <FriendlyMatchTable>
                                    {matches.map((match, index) => (
                                        <FriendlyMatchTableRow key={index} {...match}/>
                                    ))}
                                </FriendlyMatchTable>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Snackbar open={errorOpen} autoHideDuration={2000} onClose={() => setErrorOpen(false)}>
                <Alert onClose={() => setErrorOpen(false)} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={2000} onClose={() => setSuccessOpen(false)}>
                <Alert onClose={() => setSuccessOpen(false)} severity="success">
                    Match added successfully
            </Alert>
            </Snackbar>
        </>
    )

}

export default AdminMatchPage;