import { Paper, Box, ListItem, ListItemText, ListItemIcon, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info';
import InputLabel from '../UI/InputLabel';

function OverviewCard(props) {
    const { minDifficulty, maxDifficulty, duration, doubleElimination } = props;
    return (
        <Paper elevation={6}>
            <ListItem divider>
                <ListItemIcon> <InfoIcon /> </ListItemIcon>
                <ListItemText primary={<Typography variant='h6'> Overview </Typography>} />
            </ListItem>
            <Box p={2}>
                <Box mb={3}>
                    <InputLabel> Format </InputLabel>
                    <Typography noWrap> 
                        {doubleElimination ? "Double Elimination" : "Single Elimination"}
                    </Typography>
                </Box>
                <Box mb={3}>
                    <InputLabel> Difficulty </InputLabel>
                    <Typography noWrap> 
                        {minDifficulty} — {maxDifficulty}
                    </Typography>
                </Box>
                <Box>
                    <InputLabel> Match Duration </InputLabel>
                    <Typography noWrap> 
                        {duration} minutes
                    </Typography>
                </Box>
            </Box>
        </Paper>
    )
}

export default OverviewCard;