import { Grid, makeStyles, Button, Typography, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Link } from 'react-router-dom'
import TextButton from '../UI/TextButton';
import InputText from '../UI/InputText';
import InputLabel from '../UI/InputLabel';
import { useState, useRef } from 'react';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: 0
    },
    registerButton: {
        width: '100%',
        height: '50px',
        textTransform: 'none',
        fontSize: '18px',
        marginRight: 0,
        marginBottom: '10px'
    },
    verifyButton: {
        width: '100%',
        height: '50px'
    },
    title: {
        fontWeight: 'bold'
    },
    loginLink: {
        textDecoration: 'none'
    },
    emailLoading: {
        color: 'white'
    }
}));



const RegisterForm = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const fields = ['username', 'name', 'school', 'password', 'confirmPassword', 'email', 'verificationCode', 'codeforcesHandle'];
    const formAttr = {
        username: {
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'Username',
            id: 'username'
        },
        name: {
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'Name',
            id: 'name'
        },
        school: {
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'School',
            id: 'school'
        },
        password: {
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'Password',
            id: 'password'
        },
        confirmPassword: {
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'Re-enter password',
            id: 'confirm-password'
        },
        email: {
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'E-mail',
            id: 'email'
        },
        verificationCode: {
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'Code',
            id: 'verification-code'
        },
        codeforcesHandle: {
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'Codeforces handle',
            id: 'codeforces-handle'
        }
    }

    const onRegister = async (e) => {
        e.preventDefault();

        let haveError = false;
        fields.forEach(field => {
            formAttr[field].setError(null);
        });

        fields.forEach(field => {
            if (formAttr[field].ref.current.value.trim() === '') {
                formAttr[field].setError(`${formAttr[field].label} cannot be empty`);
                haveError = true;
            }
        });

        if (haveError) return;
        const formData = fields.reduce((res, val) => {
            res[val] = formAttr[val].ref.current.value.trim();
            return res;
        }, {});

        if (formData.password != formData.confirmPassword) {
            formAttr['confirmPassword'].setError('Entered passwords are not the same');
        }

        delete formData.confirmPassword;

        const fetchOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        }

        const request = await fetch(`/auth/register`, fetchOptions);
        if (request.status !== 200) {
            console.log('An error occured, please try again later');
            return;
        }

        const response = await request.json();
        if (response.status === "FAILED") {
            if (response.body.error.invalidEmail) {
                formAttr['email'].setError('Invalid email');
            }
            if (response.body.error.invalidVerification) {
                formAttr['verificationCode'].setError('Code incorrect');
            }
            const used = response.body.error.used;
            if (used) {
                used.forEach(field => {
                    formAttr[field].setError(`${formAttr[field].label} taken`)
                })
            }
        } else {
            history.push("/")
        }
    }

    return (
        <form className={classes.root} onSubmit={onRegister}>
            <Grid container direction="column" spacing={3} className={classes.root}>
                <Grid item xs={12}>
                    <Typography align='center' variant="h4" className={classes.title}>  Create an account  </Typography>
                </Grid>

                {['username', 'name'].map((field) =>
                    <Grid item xs={12} key={field}>
                        <AttrField formAttr={formAttr} field={field} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <SchoolField formAttr={formAttr} schools={props.schools}/>
                </Grid>
                {['password', 'confirmPassword'].map((field) =>
                    <Grid item xs={12} key={field}>
                        <AttrField formAttr={formAttr} field={field} type='password' />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item sm={9} xs={11}>
                            <EmailField formAttr={formAttr} />
                        </Grid>
                        <Grid item sm={3} xs={4}>
                            <AttrField formAttr={formAttr} field='verificationCode' />
                        </Grid>
                    </Grid>
                </Grid>
                {['codeforcesHandle'].map((field) =>
                    <Grid item xs={12} key={field}>
                        <AttrField formAttr={formAttr} field={field} />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.registerButton}
                        type="submit"
                    >
                        Register
                    </Button>
                    <Typography color="textSecondary"> Already have an account? <Link to="/login" className={classes.loginLink}> <TextButton> Login </TextButton> </Link> </Typography>
                </Grid>
            </Grid>
        </form>
    );
};

// Form field for everything except school and email
const AttrField = (props) => {
    const { field, formAttr, type } = props;
    return (
        <>
            <InputLabel> {formAttr[field].label} </InputLabel>
            <InputText
                id={formAttr[field].id}
                fullWidth inputRef={formAttr[field].ref}
                error={formAttr[field].error ? true : false}
                helperText={formAttr[field].error}
                type={type}
            />
        </>
    );
}


const EmailField = (props) => {
    const classes = useStyles();
    const { formAttr } = props;

    const [verifyLoading, setVerifyLoading] = useState(false);
    const [verifyButtonText, setVerifyButtonText] = useState('Verify');

    const onSendVerification = async (e) => {
        e.preventDefault();

        const email = formAttr['email'].ref.current.value.trim();
        if (email.length === 0) {
            formAttr['email'].setError('E-mail cannot be empty');
            return;
        }
        setVerifyLoading(true);

        const fetchOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        }

        const request = await fetch(`/auth/email-verify`, fetchOptions);
        if (request.status !== 200) {
            formAttr['email'].setError('An error occured, please try again later');
            setVerifyLoading(false);
            return;
        }

        const response = await request.json();
        if (response.status === "OK") {
            setVerifyLoading(false);
            setVerifyButtonText('Sent');
            setTimeout(() => {
                setVerifyButtonText('Verify');
            }, 700)
        } else {
            formAttr['email'].setError(response.body.errorMessages[0]);
        }
    }

    return (
        <>
            <InputLabel> E-mail </InputLabel>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={9}>
                    <InputText
                        id="email"
                        fullWidth type="email"
                        inputRef={formAttr['email'].ref}
                        error={formAttr['email'].error ? true : false}
                        helperText={formAttr['email'].error}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" color="primary" className={classes.verifyButton} onClick={onSendVerification}>
                        {verifyLoading && <CircularProgress size={20} className={classes.emailLoading} />}
                        {!verifyLoading && <> {verifyButtonText} </>}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

const SchoolField = (props) => {
    const { formAttr, schools = [] } = props;
    return (

        <>
            <InputLabel> {formAttr['school'].label} </InputLabel>
            <Autocomplete
                freeSolo
                id={formAttr['school'].id}
                options={schools}
                getOptionLabel={(option) => option}
                renderInput={(params) =>
                    <InputText
                    fullWidth
                        inputRef={formAttr['school'].ref}
                        error={formAttr['school'].error ? true : false}
                        helperText={formAttr['school'].error}
                        {...params}
                    />
                }
            />
        </>
    )
}

export default RegisterForm;