import { Paper, Table, TableHead, TableRow, TableCell, TableBody, darken, makeStyles, TableContainer } from '@material-ui/core';
import dateToString from '../../utils/dateToString';

const useStyles = makeStyles((theme) => ({
    submissionRow: {
        '&:hover': {
            backgroundColor: darken(theme.palette.background.paper, 0.2)
        },
    },
    submissionCell: {
        display: 'contents',
        '&>a': {
            textDecoration: 'none',
        }
    }
}));

function SubmissionTable(props) {
    return (
        <TableContainer component={Paper} elevation={6}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align='center'>Time</TableCell>
                    <TableCell align='center'>Username</TableCell>
                    <TableCell align='center'>Task ID</TableCell>
                    <TableCell align='center'>Verdict</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.children}
            </TableBody>
        </Table>
        </TableContainer>
    );
}

function SubmissionTableRow(props) {
    const classes = useStyles();
    return (
        <TableRow className={classes.submissionRow}>
            <SubmissionTableCell contestId={props.contestId} remoteId={props.remoteId}>{dateToString(props.time)}</SubmissionTableCell>
            <SubmissionTableCell contestId={props.contestId} remoteId={props.remoteId}>{props.user}</SubmissionTableCell>
            <SubmissionTableCell contestId={props.contestId} remoteId={props.remoteId}>{props.contestId+props.taskIndex}</SubmissionTableCell>
            <SubmissionTableCell contestId={props.contestId} remoteId={props.remoteId}>{props.verdict}</SubmissionTableCell>
        </TableRow>
    );
}

function SubmissionTableCell(props) {
    const classes = useStyles();
    return (
        <td className={classes.submissionCell}>
            <TableCell
                align='center'
                component='a'
                href={`https://codeforces.com/contest/${props.contestId}/submission/${props.remoteId}`}
                target="_blank"
                rel="noreferrer noopener"
            >
                {props.children}
            </TableCell>
        </td>
    );
}

export {SubmissionTable as default, SubmissionTableRow};
