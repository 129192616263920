
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import FireIcon from '@material-ui/icons/LocalFireDepartment';
import { makeStyles } from '@material-ui/core';
import MatchListTable from '../match-list/MatchListTable';
import TournamentsTable from '../tournaments-list/TournamentsTable';

const useStyles = makeStyles({
    title: {
        paddingTop: 0,
        paddingBottom: 0
    }
})


const HistoryCard = (props) => {
    return (
        <>
            <TournamentsAccordion tournaments={props.tournaments}/>
            <MatchesAccordion matches={props.matches} />
        </>
    )
}

const TournamentsAccordion = (props) => {
    const classes = useStyles();
    return (
        <Accordion elevation={6}>
            <AccordionSummary component={Paper} expandIcon={<ExpandMoreIcon />}>
                <ListItem className={classes.title}>
                    <ListItemIcon> <TrophyIcon /> </ListItemIcon>
                    <ListItemText disableTypography primary={<Typography variant='h6'> Tournaments </Typography>} />
                </ListItem>
            </AccordionSummary>
            <AccordionDetails>
                <TournamentsTable elevation={0} tournaments={props.tournaments} />
            </AccordionDetails>
        </Accordion>
    )
}

const MatchesAccordion = (props) => {
    const classes = useStyles();
    return (
        <Accordion elevation={6}>
            <AccordionSummary component={Paper} expandIcon={<ExpandMoreIcon />} >
                <ListItem className={classes.title}>
                    <ListItemIcon> <FireIcon /> </ListItemIcon>
                    <ListItemText disableTypography primary={<Typography variant='h6'> Matches </Typography>} />
                </ListItem>
            </AccordionSummary>
            <AccordionDetails>
                <MatchListTable elevation={0} matches={props.matches} />
            </AccordionDetails>
        </Accordion>
    )
}

export default HistoryCard;