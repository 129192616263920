import { useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FireIcon from '@material-ui/icons/LocalFireDepartment';
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import AdminIcon from '@material-ui/icons/SupervisorAccount';
import GroupsIcon from '@material-ui/icons/Groups'
import LogoutIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from 'react-router-dom';
import { AccessLevelContext } from '../PrivateRoute';
import { ACCESS_LEVEL_ENUM } from '../../constants';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: theme.palette.background.default
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('sm')]: {
            width: '0'
        }
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        paddingLeft: '7px',
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingLeft: '7px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        paddingLeft: '7px',
        overflowX: 'hidden',
        width: theme.spacing(8) + 3,
        [theme.breakpoints.down('md')]: {
            width: 0,
            paddingLeft: 0,
            border: 'none'
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        width: '100%',
        flexGrow: 1,
        padding: theme.spacing(3),
        overflow: 'auto',
    }
}));

const generalRoutes = [
    {
        icon: <HomeIcon />,
        text: 'Home',
        to: '/'
    },
    {
        icon: <FireIcon />,
        text: 'Matches',
        to: '/match-list'
    },
    {
        icon: <TrophyIcon />,
        text: 'Tournaments',
        to: '/tournaments'
    }
]

const userRoutes = [
    {
        icon: <PersonIcon />,
        text: 'Profile',
        to: '/profile'
    },
    {
        icon: <GroupsIcon />,
        text: 'Teams',
        to: '/teams'
    },
    {
        icon: <SettingsIcon />,
        text: 'Settings',
        to: '/settings',
    },
]

const MainNavigation = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const onLogout = useCallback(async () => {
        const response = await fetch(`/auth/logout`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.status === 200) {
            history.push("/login");
        }
    }, [history]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}

                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        JSOI Lockout System
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {generalRoutes.map(route => (
                        <ListItem
                            key={route.text}
                            button
                            component={Link}
                            to={route.to}
                            onClick={mobile ? handleDrawerClose : null}
                        >
                            <ListItemIcon>{route.icon}</ListItemIcon>
                            <ListItemText primary={route.text} />
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                    {userRoutes.map(route => (
                        <ListItem
                            key={route.text}
                            button
                            component={Link}
                            to={route.to}
                            onClick={mobile ? handleDrawerClose : null}
                        >
                            <ListItemIcon>{route.icon}</ListItemIcon>
                            <ListItemText primary={route.text} />
                        </ListItem>
                    ))}
                    <AccessLevelContext.Consumer>
                        {value => value >= ACCESS_LEVEL_ENUM.SCHOOL_ADMIN &&
                            <ListItem
                                button
                                key='admin'
                                component={Link}
                                to='/admin'
                                onClick={mobile ? handleDrawerClose : null}
                            >
                                <ListItemIcon><AdminIcon /> </ListItemIcon>
                                <ListItemText primary='Admin' />
                            </ListItem>
                        }
                    </AccessLevelContext.Consumer>
                    <ListItem
                        button
                        key='logout'
                        onClick={onLogout}
                    >
                        <ListItemIcon> <LogoutIcon /> </ListItemIcon>
                        <ListItemText primary='Logout' />
                    </ListItem>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
}


export default MainNavigation;