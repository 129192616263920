import { darken, makeStyles, Typography, Paper, Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    ongoingTournamentList: {
        padding: 0
    },
    ongoingTournamentCell: {
        '&:hover': {
            backgroundColor: darken(theme.palette.background.paper, 0.2)
        },
    }
}));

const TournamentsCard = props => {
    const classes = useStyles();
    const [tournaments, setTournaments] = useState([]);
    const getTournaments = useCallback(async () => {
        const response = await fetch('/api/tournaments/ongoing-tournaments', { credentials: 'include' });
        setTournaments((await response.json()).body.tournaments);
    }, []);
    useEffect(() => { getTournaments() }, [getTournaments]);
    return (
        <Paper elevation={6}>
            <Box overflow='hidden'>
                <List className={classes.ongoingTournamentList}>
                    <ListItem divider>
                        <ListItemIcon>
                            <TrophyIcon fontSize='default' />
                        </ListItemIcon>
                        <ListItemText size="xl" primary='Ongoing Tournaments' />
                    </ListItem>
                    {tournaments && tournaments.map((tournament) =>
                        <ListItem
                            key={tournament.id}
                            button divider
                            component={Link}
                            to={`/tournament/${tournament.id}`}
                            className={classes.ongoingTournamentCell}
                        >
                            <Typography > {tournament.name} </Typography>
                        </ListItem>
                    )}
                </List>
            </Box>
        </Paper>
    )
}

export default TournamentsCard;