import { useCallback } from 'react';
import { Paper, Box, Typography, Divider, Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';


function GenerateBracketCard(props) {
    const { tournamentId } = useParams();
    const onDeleteBracket = useCallback(async () => {
        await fetch(`/api/tournament/${tournamentId}/bracket`, {
            method: 'DELETE',
            credentials: 'include',
        })
        props.setSuccessOpen(true);
        props.setSuccessMessage("Bracket deleted successfully");
        props.getConfig();
        props.getTournament();
    }, [props, tournamentId]);
    const onGenerateBracket = useCallback(async () => {
        await fetch(`/api/tournament/${tournamentId}/bracket`, {
            method: 'POST',
            credentials: 'include',
        })
        props.setSuccessOpen(true);
        props.setSuccessMessage("Bracket generated successfully");
        props.getConfig();
        props.getTournament();
    }, [props, tournamentId]);
    return (
        <Paper elevation={6}>
            <Box p={4}>
                <Typography variant='h6' gutterBottom> Generate bracket </Typography>
                <Divider />
                <Box mt={2}>
                    {!props.bracket && <Button variant='contained' color='primary' onClick={onGenerateBracket}> Generate bracket </Button>}
                    {props.bracket && <Button variant='contained' color='secondary' onClick={onDeleteBracket}> Destroy bracket </Button>}
                </Box>
            </Box>
        </Paper>
    )
}

export default GenerateBracketCard;