import { Table, TableHead, TableRow, TableCell, TableBody, Paper, TableContainer, darken, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import dateToString from '../../utils/dateToString';

const useStyles = makeStyles((theme) => ({
    matchRow: {
        '&:hover': {
            backgroundColor: darken(theme.palette.background.paper, 0.2)
        },
    },
    matchCell: {
        display: 'contents',
        '&>a': {
            textDecoration: 'none',
        }
    }
}));

function MatchListTable(props) {
    return (
        <TableContainer component={Paper} elevation={props.elevation ?? 6}>
            <Table>
                <MatchListTableHead />
                <TableBody>
                    {props.matches.map(match => {
                        let status = "Waiting";
                        if (match.hasEnded) status = "Ended";
                        else if (match.startTime != null && match.startTime <= Date.now()) status = "Running";
                        else if (match.scheduledStartTime > Date.now()) status = "Scheduled";
                        return <MatchListTableRow key={match.id} status={status} {...match} />
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function MatchListTableHead() {
    return (
        <TableHead>
            <TableRow>
                <TableCell align='center' > <Typography noWrap> ID </Typography>  </TableCell>
                <TableCell align='center' > <Typography noWrap> Match </Typography> </TableCell>
                <TableCell align='center' > <Typography noWrap> Scheduled Start Time </Typography> </TableCell>
                <TableCell align='center' > <Typography noWrap> Status </Typography> </TableCell>
                <TableCell align='center' > <Typography noWrap> Winner </Typography> </TableCell>
            </TableRow>
        </TableHead>
    )
}

function MatchListTableRow(props) {
    const classes = useStyles();
    return (
        <TableRow className={classes.matchRow}>
            <MatchListTableCell id={props.id}> {props.id} </MatchListTableCell>
            <MatchListTableCell id={props.id}> {props.contestant1 + " vs " + props.contestant2} </MatchListTableCell>
            <MatchListTableCell id={props.id}> {dateToString(props.scheduledStartTime)} </MatchListTableCell>
            <MatchListTableCell id={props.id}> {props.status} </MatchListTableCell>
            <MatchListTableCell id={props.id}> {props.winner == null ? "" : props.winner} </MatchListTableCell>
        </TableRow>
    )
}

function MatchListTableCell(props) {
    const classes = useStyles();
    return (
        <td className={classes.matchCell}>
            <TableCell align='center' component={Link} to={`/match/${props.id}`}>
                <Typography noWrap> {props.children} </Typography>
            </TableCell>
        </td>
    );
}

export default MatchListTable;
