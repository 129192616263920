import { Paper, Typography, Box } from '@material-ui/core';
import MatchListTable from '../../match-list/MatchListTable';


function MatchesCard(props) {
    if (!props.matches || props.matches.length === 0) {
        return (
            <Paper elevation={6}>
                <Box p={2}>
                    <Typography> There is no scheduled match </Typography>
                </Box>
            </Paper>
        )
    }
    return (
        <MatchListTable matches={props.matches} />
    )
}

export default MatchesCard;