import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import PrivateRoute from './components/PrivateRoute';
import LoginPage from './pages/LoginPage';
import ErrorPage from './pages/ErrorPage';
import RegisterPage from './pages/RegisterPage'
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import MatchListPage from './pages/MatchListPage';
import MatchPage from './pages/MatchPage';
import TournamentPage from './pages/TournamentPage';
import TournamentsListPage from './pages/TournamentsListPage';
import SettingsPage from './pages/SettingsPage';
import EditMatchPage from './pages/EditMatchPage';
import MainAdminPage from './pages/admin/MainAdminPage';
import MainTeamsPage from './pages/teams/MainTeamsPage';
import MainLayout from './components/layout/MainLayout';
import theme from './theme';
import '@fontsource/roboto';
import { ACCESS_LEVEL_ENUM } from './constants';
import { useState } from 'react';


function App() {
    const [accessLevel, setAccessLevel] = useState(ACCESS_LEVEL_ENUM.USER);
    return (
        <ThemeProvider theme={theme}>
            <Switch>
                <Route exact path='/login'> <LoginPage /> </Route>
                <Route exact path='/register'> <RegisterPage /> </Route>
                <Route exact path='/error'> <ErrorPage /> </Route>
                <PrivateRoute setAccessLevel={setAccessLevel}>
                    <MainLayout accessLevel={accessLevel}>
                        <Switch>
                            <Route exact path='/'> <HomePage /> </Route>
                            <Route exact path={['/profile/:username', '/profile']}>  <ProfilePage /> </Route>
                            <Route exact path="/match-list"><MatchListPage /></Route>
                            <Route exact path="/match/:matchId"><MatchPage /></Route>
                            <Route exact path={["/tournament/:tournamentId", "/tournament/:tournamentId/edit"]}><TournamentPage /></Route>
                            <Route exact path={['/tournaments', '/tournaments/add']}> <TournamentsListPage /> </Route>
                            <Route exact path="/edit-match/:matchId"><EditMatchPage /></Route>
                            <Route exact path='/settings'> <SettingsPage /> </Route>
                            <Route path={['/admin/:tab', '/admin']}>  <MainAdminPage /> </Route>
                            <Route path={['/teams/:tab', '/teams']}><MainTeamsPage /></Route>
                            <Route path='*'>
                                <Redirect to={{ pathname: '/error', state: { message: 'Page not found.' } }} />
                            </Route>
                        </Switch>
                    </MainLayout>
                </PrivateRoute>
            </Switch>
        </ThemeProvider>
    )
}

export default App;
