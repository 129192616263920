import { createMuiTheme } from '@material-ui/core';
import './font/whitney.css'


const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#7289DA'
        },
        background: {
            default: '#2f3136',
            paper: '#36393e'
        },
        text: {
            primary: '#ffffff',
            secondary: '#b5b7ba',
        }
    },
    typography: {
        fontFamily: 'Whitney Medium'
    }
});

const hColor = 'white'
theme.typography.h1.color = hColor;
theme.typography.h2.color = hColor;
theme.typography.h3.color = hColor;
theme.typography.h4.color = hColor;
theme.typography.h5.color = hColor;
theme.typography.h6.color = hColor;

export default theme;