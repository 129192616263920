import { Tab, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import StyledTabs from '../UI/StyledTabs';
import { useRouteMatch } from 'react-router-dom';
import { AccessLevelContext } from '../PrivateRoute';
import { ACCESS_LEVEL_ENUM } from '../../constants';

const AdminNavigation = () => {
    const match = useRouteMatch();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const tabValue = match.params.tab ?? 'general';

    return (
        <Paper elevation={6}>
            <AccessLevelContext.Consumer>
                {accessLevel => accessLevel === ACCESS_LEVEL_ENUM.SYSTEM_ADMIN
                    ? <StyledTabs
                        value={tabValue}
                        variant={mdDown ? 'fullWidth' : 'standard'}
                    >
                        <Tab component={Link} value='general' to='/admin' label='General' />
                        <Tab component={Link} value='users' to='/admin/users' label='Users' />
                        <Tab component={Link} value='teams' to='/admin/teams' label='Teams'/>
                        <Tab component={Link} value='problems' to='/admin/problems' label='Problems' />
                        <Tab component={Link} value='matches' to='/admin/matches' label='Matches' />
                        
                    </StyledTabs>
                    : <StyledTabs
                        value={tabValue}
                        variant={smDown ? 'fullWidth' : 'standard'}
                    >
                        <Tab component={Link} value='general' to='/admin' label='General' />
                        <Tab component={Link} value='users' to='/admin/users' label='Users' />
                        <Tab component={Link} value='teams' to='/admin/teams' label='Teams'/>
                    </StyledTabs>
                }
            </AccessLevelContext.Consumer>
        </Paper>
    )
}

export default AdminNavigation;
