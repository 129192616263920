import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 'bold',
        color: theme.palette.text.secondary, // old: #8e9297
        fontSize: '14px',
        textTransform: 'uppercase',
        marginBottom: theme.spacing(1)
    }
}));

const InputLabel = props => {
    const classes = useStyles();
    const { className, children, ...other } = props;
    return <Typography className={`${classes.root} ${className}`} {...other}> {children} </Typography>
}

export default InputLabel;