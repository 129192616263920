import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '../UI/InputLabel';
import useLocalStorage from '../../hooks/useLocalStorage';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        padding: '20px',
        marginTop: '-40px'
    }
})

const userAttributes = [
    {
        key: 'username',
        label: 'Team name'
    },
    {
        key: 'createUser',
        label: 'Invite sent from user'
    },
    {
        key: 'invitedUser',
        label: 'Invite sent to user'
    }
];

const UserDetails = (props) => {
    const classes = useStyles();
    const { modifiedUsers, setModifiedUsers, users, setUsers, userIndex } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const updateServer = async (updates,acceptStatus) => {
        
        const fetchOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ updates }),
            credentials: 'include'
        }
        
        const response = await fetch(`/api/team/update/${users[userIndex].username}/set/${acceptStatus}`, fetchOptions);
        props.onError(response.status)
        if (response.status === 401) {
            props.onError('Unauthorized');
            return;
        }
        if (response.status === 200) {
            const result = await response.json();
            if (result.status === "FAILED") {
                let message = '';
                if (result.body.taken.length > 0) {
                    message = message + `${JSON.stringify(result.body.taken)} taken.`;
                }
                if (result.body.invalid.length > 0) {
                    if (message.length > 0) message = message + '\n';
                    message = message + `${JSON.stringify(result.body.invalid)} invalid.`;
                }
                if (result.body.rejected.length > 0) {
                    if (message.length > 0) message = message + '\n';
                    message = message + `${JSON.stringify(result.body.rejected)} cannot be modified.`;
                }
                props.onError(message);
            } else {
                setUsers(prev => {
                    const tmp = [...prev];
                    tmp[userIndex] = JSON.parse(JSON.stringify(modifiedUsers[userIndex]));
                    return tmp;
                })
                props.onSuccess();
            }
            props.reload();
            return;
        }
    }

    const onAccept = (userIndex) => async () => {
        const updates = Object.keys(modifiedUsers[userIndex]).reduce((accum, key) => {
            if (modifiedUsers[userIndex][key] !== users[userIndex][key]) {
                accum.push({
                    key,
                    value: modifiedUsers[userIndex][key]
                });
            }
            return accum;
        }, []);
        updateServer(updates,'true');
    }

    const onDecline = (userIndex) =>  async() => {
        const updates = Object.keys(modifiedUsers[userIndex]).reduce((accum, key) => {
            if (modifiedUsers[userIndex][key] !== users[userIndex][key]) {
                accum.push({
                    key,
                    value: modifiedUsers[userIndex][key]
                });
            }
            return accum;
        }, []);
        updateServer(updates,'false');
    }

    const onAttributeChange = (attribute) => (event) => {
        setModifiedUsers(prev => {
            const tmp = [...prev];
            tmp[userIndex] = {
                ...tmp[userIndex],
                [attribute]: (attribute === 'inviteAccepted' ? event.target.checked : event.target.value)
            };
            return tmp;
        })
    }

    const user = useLocalStorage('user',null)[0];

    return (
        <Grid container spacing={3} className={classes.root}>
            <AttributeInputGroup
                onAttributeChange={onAttributeChange}
                {...props}
            />

            {user.username===users[userIndex].createUser
            ?<Grid item xs={12}>Verified: {users[userIndex].verified?'Yes':'No'}</Grid>
            :(
                users[userIndex].inviteAccepted===false
                ?<Grid item xs={12}>
                    <Grid container spacing={2} direction='row' justify={mobile ? 'center' : 'flex-start'}>
                        <Grid item>
                            <Button fullWidth variant='outlined' style={{borderColor: 'transparent'}} color='primary' onClick={onAccept(userIndex)}> Accept Invite </Button>
                        </Grid>
                        <Grid item>
                            <Button fullWidth variant='outlined' style={{color: '#e74c3c', borderColor: 'transparent'}} onClick={onDecline(userIndex)}> Decline Invite </Button>
                        </Grid>
                    </Grid>
                </Grid>
                :<Grid item xs={12}>
                    Verified: {users[userIndex].verified?'Yes':'No'}
                </Grid>
            )}
        </Grid>
    )
}

const AttributeInputGroup = (props) => {
    const { userIndex, users, modifiedUsers } = props;

    return (
        <>
            {userAttributes.map((attribute) =>
                <Grid item key={`${users[props.userIndex].username}-${attribute.key}`} xs={12} sm={6} md={4}>
                    <InputLabel> {attribute.label} </InputLabel>
                    {modifiedUsers[userIndex][attribute.key]}
                </Grid>
            )}
        </>
    )
}

export default UserDetails;