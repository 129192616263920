import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputText from '../../UI/InputText';
import InputLabel from '../../UI/InputLabel';



const FitlerCard = (props) => {
    return (
        <Card elevation={6}>
            <CardContent>
                <Grid container spacing={3} direction='column'>
                    <Grid item xs={12}>
                        <Typography variant='h6'> Filter </Typography>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <InputLabel> Name / Username </InputLabel>
                        <InputText fullWidth size='small' onChange={(e) => props.setNameFilterValue(e.target.value)}></InputText>
                    </Grid>
                    <Grid item>
                        <InputLabel> School </InputLabel> <Autocomplete
                            multiple
                            options={props.schools}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            onChange={(e, v) => props.setSchoolFilterValues(v)}
                            renderInput={(params) => {
                                return <InputText {...params} fullWidth size="small" ></InputText>
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default FitlerCard;