const ACCESS_LEVEL_ENUM = {
    USER: 0,
    SCHOOL_ADMIN: 1,
    SYSTEM_ADMIN: 2
}

const hiddenSchools = [
    "JSOI"
]

export { ACCESS_LEVEL_ENUM, hiddenSchools };