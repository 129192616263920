import { Grid, makeStyles, Paper, Button, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import Logo from '../components/UI/Logo';

const useStyles = makeStyles({
    root: {
        padding: '30px',
        maxWidth: '90vw',
        borderRadius: '10px'
    },
    title: {
        fontWeight: 'bold'
    }
});

const ErrorPage = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const message = (location.state && location.state.message ? location.state.message : 'Unexpected error')

    const onGoBack = () => {
        if (history.length === 0) {
            history.push('/');
            return;
        }
        history.goBack();
        return;
    }

    return (
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
            <Grid item>
                <Logo />
            </Grid>
            <Grid item>
                <Paper elevation={6} className={classes.root} >
                    <Grid container direction="column" alignItems="center" spacing={3}>
                        <Grid item>
                            <Typography variant="h4" className={classes.title}> Oops, An error occured! </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5"> Error: {message} </Typography>
                        </Grid>
                        <Grid item>
                            <Button color='primary' variant='contained' onClick={onGoBack}> Go Back </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ErrorPage;