import LoginForm from '../components/auth/LoginForm'
import { Grid, makeStyles, Paper } from '@material-ui/core';
import Logo from '../components/UI/Logo';

const useStyles = makeStyles({
    root: {
        padding: '30px',
        paddingRight: '5px',
        maxWidth: '90vw',
        width: '600px',
        borderRadius: '10px'
    }
});

const LoginPage = (props) => {
    const classes = useStyles();
    return (
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
            <Grid item>
                <Logo />
            </Grid>
            <Grid item>
                <Paper elevation={6} className={classes.root} >
                    <LoginForm />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default LoginPage;