import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";

const usePageInit = (path, method, body = null) => {
    const [response, setResponse] = useState(null);
    const history = useHistory();

    const reload = useCallback(async () => {
        const fetchOptions = {
            method,
            headers: { 'Content-Type': 'application/json' },
            body,
            credentials: 'include'
        }
        const response = await fetch(`${path}`, fetchOptions);
        if (response.status === 401) {
            const message = await response.text();
            if (message === 'Unauthorized') {
                history.push('/login');
                return;
            }
            if (message === 'No access') {
                return history.push('/error', { message: 'Page not found' });
            }
            history.push('/error', { message });
        } else if (response.status === 200) {
            const result = await response.json();
            setResponse(result);
        } else {
            return history.push('/error', { message: 'Unexpected error' });
        }
    }, [body, history, method, path]);

    useEffect(() => {
        reload();
    }, [reload])

    return [response, reload];
}

export default usePageInit;