import { Paper, Box, List, ListItem, ListItemIcon, ListItemText, makeStyles, IconButton, Button, Typography } from '@material-ui/core'
import AnnouncementsIcon from '@material-ui/icons/Campaign';
import InputLabel from '../UI/InputLabel';
import InputText from '../UI/InputText';
import { useCallback, useEffect, useState } from 'react';
import { AccessLevelContext } from '../PrivateRoute';
import AddIcon from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ACCESS_LEVEL_ENUM } from '../../constants';
import useLocalStorage from '../../hooks/useLocalStorage';
import stringToDate from '../../utils/stringToDate';
import dateToString from '../../utils/dateToString';
import TextButton from '../UI/TextButton';

const useStyles = makeStyles((theme) => ({
    announcementList: { padding: 0 },
    announcementBody: { width: '100%', overflowX: 'auto' },
    announcementAuthor: { color: 'yellow' },
    announcementContent: {
        '& a': {
            color: '#04a4e3',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer'
            }
        }
    }
}));

const AnnouncementsCard = props => {
    const [announcements, setAnnouncements] = useState([]);
    const [add, setAdd] = useState(false);

    const getAnnouncements = useCallback(async () => {
        const result = await (await fetch('/api/announcements', { credentials: 'include' })).json();
        setAnnouncements(result.body.announcements);
    }, [])

    useEffect(() => getAnnouncements(), [getAnnouncements]);

    const onToggleAdd = () => {
        setAdd(prev => prev === true ? false : true);
    }

    return (
        <Paper elevation={6}>
            <Box oveflow='hidden'>
                <ListItem divider>
                    <ListItemIcon>
                        <AnnouncementsIcon />
                    </ListItemIcon>
                    <ListItemText primary={<Typography noWrap> Announcements </Typography>} />
                    <AccessLevelContext.Consumer>
                        {accessLevel => accessLevel >= ACCESS_LEVEL_ENUM.SYSTEM_ADMIN &&
                            <IconButton onClick={onToggleAdd}>
                                {add ? <ArrowBack /> : <AddIcon />}
                            </IconButton>
                        }
                    </AccessLevelContext.Consumer>
                </ListItem>
                {add
                    ? <ModifyAnnouncement
                        create
                        getAnnouncements={getAnnouncements}
                        onToggleAdd={onToggleAdd}
                    />
                    : <AnnouncementsList
                        announcements={announcements}
                        getAnnouncements={getAnnouncements}
                    />
                }
            </Box>
        </Paper>
    );
}

function AnnouncementsList(props) {
    const classes = useStyles();
    return (
        <Box>
            <List className={classes.announcementList}>
                {props.announcements && props.announcements.map(announcement =>
                    <ListItem key={announcement.id} divider>
                        <AnnouncementBody
                            author={announcement.author}
                            announcementId={announcement.id}
                            time={announcement.time}
                            content={announcement.content}
                            getAnnouncements={props.getAnnouncements}
                        />
                    </ListItem>
                )}
            </List>
        </Box>
    )
}

function AnnouncementBody(props) {
    const classes = useStyles();
    const [modifying, setModifying] = useState(false);
    const onToggleModify = useCallback(() => {
        setModifying(prev => prev === true ? false : true)
    }, []);

    return (
        <div className={classes.announcementBody}>
            <Box mb={1}>
                <Typography className={classes.announcementAuthor}> {props.author} </Typography>
                <Typography variant='subtitle2' color='textSecondary'> {dateToString(props.time)} </Typography>
            </Box>
            <AccessLevelContext.Consumer>
                {accessLevel => accessLevel >= ACCESS_LEVEL_ENUM.SYSTEM_ADMIN &&
                    <TextButton onClick={onToggleModify}> {modifying ? 'Back' : 'Modify'} </TextButton>
                }
            </AccessLevelContext.Consumer>
            {modifying
                ? <ModifyAnnouncement
                    toggleModify={onToggleModify}
                    {...props}
                />
                : <div
                    dangerouslySetInnerHTML={{ __html: props.content }}
                    className={classes.announcementContent}
                />
            }
        </div>);
}

function ModifyAnnouncement(props) {
    const [time, setTime] = useState(dateToString(props.time ?? new Date()));
    const [timeError, setTimeError] = useState(null);
    const [content, setContent] = useState(props.content ?? "");
    const user = useLocalStorage('user', null)[0];

    const onCreate = useCallback(async () => {
        setTimeError(null);
        if (!stringToDate(time)) return setTimeError("Invalid Date");
        await fetch('/api/announcement', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                author: user.username,
                time: stringToDate(time),
                content,
            }),
            credentials: 'include',
        });
        props.onToggleAdd();
        props.getAnnouncements();
    }, [props, time, content, user.username]);

    const onModify = useCallback(async () => {
        setTimeError(null);
        if (!stringToDate(time)) return setTimeError("Invalid Date");
        await fetch(`/api/announcement/${props.announcementId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                time: stringToDate(time),
                content: content,
            }),
            credentials: 'include',
        });
        props.toggleModify();
        props.getAnnouncements();
    }, [props, time, content]);

    const onDelete = useCallback(async () => {
        await fetch(`/api/announcement/${props.announcementId}`, { method: 'DELETE', credentials: 'include' });
        props.toggleModify();
        props.getAnnouncements();
    }, [props]);

    const onChangeTime = (event) => { setTime(event.target.value); }
    const onChangeContent = (event) => {
        setContent(event.target.value);
    }

    return (
        <Box p={2}>
            <Box mb={2}>
                <InputLabel> Time </InputLabel>
                <InputText value={time} fullWidth size='small' error={timeError !== null} helperText={timeError} onChange={onChangeTime} />
            </Box>

            <Box mb={2}>
                <InputLabel> Content </InputLabel>
                <InputText value={content} multiline rows={10} fullWidth size='small' onChange={onChangeContent} />
            </Box>
            <Button onClick={props.create ? onCreate : onModify} color='primary'> {props.create ? "Create" : "Save"} </Button>
            {!props.create && <Button color='secondary' onClick={onDelete}> Delete </Button>}
        </Box>
    )
}

export default AnnouncementsCard;