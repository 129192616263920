import { Route, Switch, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import AdminNavigation from '../../components/admin/AdminNavigation';
import AdminGeneral from '../../components/admin/AdminGeneral';
import AdminUsersPage from './AdminUsersPage';
import AdminProblemsPage from './AdminProblemsPage';
import AdminMatchPage from './AdminMatchPage';
import AdminTeamsPage from './AdminTeamsPage';

const MainAdminPage = (props) => {

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <AdminNavigation />
            </Grid>
            <Grid item >
                <Switch>
                    <Route exact path='/admin/'> <AdminGeneral /> </Route>
                    <Route exact path='/admin/users'> <AdminUsersPage /> </Route>
                    <Route exact path='/admin/problems'> <AdminProblemsPage /> </Route>
                    <Route exact path='/admin/matches'> <AdminMatchPage /></Route>
                    <Route exact path='/admin/teams'><AdminTeamsPage /></Route>
                    <Route path='*'> <Redirect to='/error' /></Route>
                </Switch>
            </Grid>
        </Grid>
    )
}


export default MainAdminPage;