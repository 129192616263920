import {makeStyles, Paper, List, ListItem, ListItemIcon, ListItemText, Box, Button} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {TestNotificationsButton} from '../match/EnableNotificationsButton';

const useStyles = makeStyles(() => ({
    notificationsCardList: {
        padding: 0
    }
}));

function NotificationsCard() {
    const classes = useStyles();
    if (!('Notification' in window)) return null;
    return (
        <Paper elevation={6}>
            <List className={classes.notificationsCardList}>
                <ListItem divider>
                    <ListItemIcon>
                        <NotificationsIcon />
                    </ListItemIcon>
                    <ListItemText primary='Notifications' />
                </ListItem>
                <ListItem divider>
                    <Box mx="auto">
                        <TestNotificationsButton />
                    </Box>
                </ListItem>
            </List>
        </Paper>
    );
}

export default NotificationsCard;
