import { Paper, Typography, Grid, Box, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import FireIcon from '@material-ui/icons/LocalFireDepartment';
import { useEffect, useState, useCallback } from 'react';
import MatchListTable from '../components/match-list/MatchListTable';

function MatchListPage() {
    const [matches, setMatches] = useState([]);
    const getMatches = useCallback(async () => {
        const matches = await fetch(`/api/match-list`, {
            credentials: 'include'
        }).then(res => res.json());
        return matches;
    }, []);
    useEffect(() => {
        getMatches().then(matches => {
            setMatches(matches.sort((a, b) => {
                return b.scheduledStartTime - a.scheduledStartTime;
            }));
        });
    }, [getMatches]);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageTitle/>
            </Grid>
            <Grid item xs={12}>
                <MatchListTable matches={matches} />
            </Grid>
        </Grid>
    )
}

function PageTitle() {
    return (
        <Paper elevation={6}>
            <Box overflow='hidden'>
                <ListItem>
                    <ListItemIcon>
                        <FireIcon fontSize='large'/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant='h5'> Matches </Typography>} />
                </ListItem>
            </Box>
        </Paper>
    )
}

export default MatchListPage;