import { Grid, Button, Box, Paper, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import InputText from '../UI/InputText';
import InputLabel from '../UI/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ConfigIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

function MatchConfig(props) {
    const { matchId, config, setConfig } = props;

    const updateMatchConfig = async () => {
        let res = await fetch(`/api/edit-match-config/${matchId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                host: config.host,
                duration: +config.duration,
                minDifficulty: +config.minDifficulty,
                maxDifficulty: +config.maxDifficulty,
                scheduledStartTime: config.scheduledStartTime,
                hidden: config.hidden
            }),
            credentials: 'include'
        });
        console.log(config.hidden);
        if (res.status === 401) {
            props.onError("Unauthorized");
            return;
        }
        if(res.status === 200) {
            const result = await res.json();
            if (result.status === "FAILED") {
                let message = '';
                if (result.body.error) {
                    if (message.length > 0) message = message + '\n';
                    message = message + result.body.error;
                }
                if (result.body.invalid.length > 0) {
                    if (message.length > 0) message = message + '\n';
                    message = message + `${JSON.stringify(result.body.invalid)} invalid.`;
                }
                if (result.body.rejected.length > 0) {
                    if (message.length > 0) message = message + '\n';
                    message = message + `${JSON.stringify(result.body.rejected)} cannot be modified.`;
                }
                props.onError(message);
            } else {
                props.onSuccess("Match saved successfully");
            }
            return;
        }
    }

    const onSave = async () => {
        updateMatchConfig();
    }

    
    const onHiddenToggle = () => {
        setConfig(prev => {
            const tmp = {...prev};
            tmp.hidden = (!tmp.hidden)
            return tmp;
        })
    }

    const onChange = (event) => {
        setConfig(prev => {
            const tmp = {...prev};
            tmp[event.target.name] = event.target.value;
            return tmp;
        });
    }

    const onHostChange = (id) => (event) => {
        setConfig(prev => {
            const tmp = {...prev};
            tmp.host[id] = event.target.value;
            return tmp;
        });
    }

    const onHostAdd = () => {
        setConfig(prev => {
            const tmp = {...prev};
            tmp.host.push("");
            return tmp;
        });
    }

    const onHostRemove = (id) => () => {
        let host = config.host;
        host.splice(id, 1);
        setConfig(prev => ({...prev, host}));
    }

    return (
        <>
            <Paper elevation={6}>
                <List>
                    <ListItem divider>
                        <ListItemIcon>
                            <ConfigIcon />
                        </ListItemIcon>
                        <ListItemText styles={{"color":"white"}} primary='Configuration'/>
                    </ListItem>
                    <ListItem>
                        <Box width="100%">
                            <Box mt={2} mb={1} width="100%">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <InputLabel>Scheduled Start Time</InputLabel>
                                        <InputText value={config.scheduledStartTime} name="scheduledStartTime" fullWidth onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <InputLabel>Duration</InputLabel>
                                        <InputText value={config.duration} name="duration" fullWidth onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <InputLabel>Difficulty</InputLabel>
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <Box width="100%"><InputText value={config.minDifficulty} name="minDifficulty" fullWidth onChange={onChange} /></Box>
                                            <Box>-</Box>
                                            <Box width="100%"><InputText value={config.maxDifficulty} name="maxDifficulty" fullWidth onChange={onChange} /></Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mb={2} width="100%">
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <InputLabel>Host</InputLabel>
                                    <Button color="primary" onClick={onHostAdd}><AddIcon /></Button>
                                </Box>
                                <Grid container spacing={3}>
                                    {config.host.map((host, index) => (
                                        <Grid item key={index} xs={12} sm={6} md={3}>
                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                <Box width="100%"><InputText value={host} fullWidth onChange={onHostChange(index)}/></Box>
                                                <Box><Button color="primary" onClick={onHostRemove(index)}><RemoveCircleOutlineIcon /></Button></Box>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                            <Grid item xs={12}>
                                <Typography>
                                    <Checkbox
                                        checked={config.hidden}
                                        onChange={onHiddenToggle}
                                        color='primary'
                                    />
                                    Hidden
                                </Typography>
                            </Grid>
                            <Box>
                                <Button variant="contained" color="primary" onClick={onSave}>Save</Button>
                            </Box>
                        </Box>
                    </ListItem>
                </List>
            </Paper>
        </>
    )
}

export default MatchConfig;
