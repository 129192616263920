import { Typography, Box, Button, Paper, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import InputText from '../UI/InputText';
import InputLabel from '../UI/InputLabel';

import StartIcon from '@material-ui/icons/PlayCircleFilled';

function StartMatch(props) {
    const { matchId } = props;

    const startContest = async () => {
        let res = await fetch(`/api/start-match/${matchId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                startTime: props.startTime
            }),
            credentials: 'include'
        });
        if (res.status === 401) {
            props.onError("Unauthorized");
            return;
        }
        if(res.status === 200) {
            const result = await res.json();
            if (result.status === "FAILED") {
                let message = '';
                if (result.body.error) {
                    if (message.length > 0) message = message + '\n';
                    message = message + result.body.error;
                }
                if (result.body.invalid.length > 0) {
                    if (message.length > 0) message = message + '\n';
                    message = message + `${JSON.stringify(result.body.invalid)} invalid.`;
                }
                if (result.body.rejected.length > 0) {
                    if (message.length > 0) message = message + '\n';
                    message = message + `${JSON.stringify(result.body.rejected)} cannot be modified.`;
                }
                props.onError(message);
            } else {
                props.onSuccess("Match saved successfully");
            }
            return;
        }
    }

    const onStart = async () => {
        startContest();
    }

    const onChange = (event) => {
        props.setStartTime(event.target.value);
    }

    return (
        <>
            <Paper elevation={6}>
                <List>
                    <ListItem divider>
                        <ListItemIcon>
                            <StartIcon />
                        </ListItemIcon>
                        <ListItemText styles={{"color":"white"}} primary='Start Match'/>
                    </ListItem>
                    <ListItem>
                        <Box width="100%">
                            <Box mt={2} mb={2}><InputLabel>Start Time</InputLabel>
                                <InputText value={props.startTime} fullWidth onChange={onChange} />
                                <Typography>* Start time must be at most 5 minutes from now</Typography>
                            </Box>
                            <Button variant="contained" color="primary" onClick={onStart}>Save</Button>
                        </Box>
                    </ListItem>
                </List>
            </Paper>
        </>
    )
}

export default StartMatch;