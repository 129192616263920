import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import usePageInit from '../../hooks/usePageInit';
import TeamFilterCard from '../../components/admin/teams/TeamFilterCard';
import TeamDetails from '../../components/admin/teams/TeamDetails';

const useStyles = makeStyles({
    userListAccordionGrid: {
        padding: '20px',
        paddingTop: '0'
    },
    userAccordion: {
        background: '#2f3136'
    }
})

const AdminTeamsPage = (props) => {
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false);
    const [response, reload] = usePageInit('/api/admin/teams/accepted', 'GET');
    const [schools, setSchools] = useState(null);
    const [users, setUsers] = useState(null);
    const [nameFilterValue, setNameFilterValue] = useState('');
    const [schoolFilterValues, setSchoolFilterValues] = useState([]);
    const [modifiedUsers, setModifiedUsers] = useState({});

    useEffect(() => {
        if (!response) return;
        setSchools(response.body.schools);
        setUsers(response.body.users);
        setModifiedUsers(JSON.parse(JSON.stringify(response.body.users)));
    }, [response]);

    if (!response || !schools || !users || !modifiedUsers) return null;

    const onError = (message) => {
        setErrorMessage(message);
        setErrorOpen(true);
    }

    const onSuccess = () => {
        setSuccessOpen(true);
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <TeamFilterCard
                        schools={schools}
                        setNameFilterValue={setNameFilterValue}
                        setSchoolFilterValues={setSchoolFilterValues}
                    />
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container direction='column' spacing={3}>
                        <Grid item>
                            <UserListAccordion
                                users={users}
                                modifiedUsers={modifiedUsers}
                                nameFilterValue={nameFilterValue}
                                schoolFilterValues={schoolFilterValues}
                                setUsers={setUsers}
                                setModifiedUsers={setModifiedUsers}
                                onError={onError}
                                onSuccess={onSuccess}
                                reload={reload}
                                verified={false}
                            />
                        </Grid>

                        <Grid item>
                            <UserListAccordion
                                users={users}
                                modifiedUsers={modifiedUsers}
                                nameFilterValue={nameFilterValue}
                                schoolFilterValues={schoolFilterValues}
                                setUsers={setUsers}
                                setModifiedUsers={setModifiedUsers}
                                onError={onError}
                                onSuccess={onSuccess}
                                reload={reload}
                                verified={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar open={errorOpen} autoHideDuration={2000} onClose={() => setErrorOpen(false)}>
                <Alert onClose={() => setErrorOpen(false)} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={2000} onClose={() => setSuccessOpen(false)}>
                <Alert onClose={() => setSuccessOpen(false)} severity="success">
                    Team saved successfully
            </Alert>
            </Snackbar>
        </>
    )
}

const UserListAccordion = (props) => {
    const classes = useStyles();

    return (
        <Accordion elevation={6} key={2} expanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'> {props.verified ? 'Verified Teams' : 'Unverified Teams'} </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction='column' spacing={2} padding={3} className={classes.userListAccordionGrid}>
                    {props.users.map((user, userIndex) => {
                        if (!user.name.includes(props.nameFilterValue) && !user.username.includes(props.nameFilterValue)) return null;
                        if (props.schoolFilterValues.length !== 0 && props.schoolFilterValues.indexOf(user.school) === -1) return null;
                        if (user.verified !== props.verified) return null;
                        //props.onError(user.username+' '+(user.inviteAccepted == true ? 'YES' : 'NO'))

                        return (<Accordion className={classes.userAccordion} key={`${user.username}-accordion`}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography variant='subtitle1'> {user.username} </Typography>
                            </AccordionSummary>
                            <AccordionDetails key={`${user.username}-accordion-details`}>
                                <TeamDetails
                                    key={`${user.username}-details`}
                                    userIndex={userIndex}
                                    {...props}
                                />
                            </AccordionDetails>
                        </Accordion>);
                    })}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default AdminTeamsPage;