import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from "react-router-dom";
import { Typography, Box, Button, Snackbar } from '@material-ui/core';
import { AccessLevelContext } from '../components/PrivateRoute';
import { Alert } from '@material-ui/lab';
import MatchConfig from '../components/edit-match/MatchConfig';
import StartMatch from '../components/edit-match/StartMatch';
import MatchProblems from '../components/edit-match/MatchProblems';
import ResetMatch from '../components/edit-match/ResetMatch';
import dateToString from '../utils/dateToString';
import { ACCESS_LEVEL_ENUM } from '../constants';
import MatchSubmissions from '../components/edit-match/MatchSubmissions';

function EditMatchPage() {
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const { matchId } = useParams();
    const [name, setName] = useState("");
    const [contestants, setContestants] = useState([]);
    const [config, setConfig] = useState({
        host: [],
        duration: 0,
        minDifficulty: 0,
        maxDifficulty: 0,
        scheduledStartTime: "",
        startTime: null,
        hidden: false
    });
    const [problems, setProblems] = useState([]);
    const [submissions, setSubmissions] = useState([]);
    const [startTime, setStartTime] = useState(dateToString(Date.now()));

    const getMatch = useCallback(async () => {
        let match = await fetch(`/api/match-config/${matchId}`, {
            credentials: 'include'
        }).then(res => res.json());
        return match;
    }, [matchId]);

    const reload = useCallback(async () => {
        getMatch().then(match => {
            setName(`${match.name} - ${match.contestant1} vs ${match.contestant2}`);
            setContestants([match.contestant1, match.contestant2]);
            setConfig({
                host: match.host,
                duration: match.duration,
                minDifficulty: match.minDifficulty,
                maxDifficulty: match.maxDifficulty,
                scheduledStartTime: match.scheduledStartTime ? dateToString(match.scheduledStartTime) : "",
                hidden: match.hidden
            });
            setProblems(prev => {
                let tmp = [...prev];
                for(let i = 0; i < match.problems.length; i++) {
                    if (tmp[i] == null) tmp[i] = {};
                    tmp[i].contestId = match.problems[i]?.contestId;
                    tmp[i].taskIndex = match.problems[i]?.taskIndex;
                    tmp[i].difficulty = match.problems[i]?.difficulty;
                    if(tmp[i].newContestId == null) tmp[i].newContestId = "";
                    if(tmp[i].newTaskIndex == null) tmp[i].newTaskIndex = "";
                }
                return tmp;
            });
            setSubmissions(match.submissions);
            if(match.startTime != null) {
                setStartTime(dateToString(match.startTime));
            }
        })
    }, [getMatch]);

    useEffect(() => {
        reload();
    }, [reload]);

    const onError = (message) => {
        setErrorMessage(message);
        setErrorOpen(true);
    }

    const onSuccess = (message) => {
        setSuccessMessage(message);
        setSuccessOpen(true);
    }

    return (
        <>
            <Box display="flex" alignItems="center">
                <Typography variant="h3">{name}</Typography>
                <Box ml="auto">
                    <Link to={`/match/${matchId}`}><Button color="primary">Back</Button></Link>
                </Box>
            </Box>
            <Box mb={3} mt={3}>
                <MatchConfig
                    matchId={matchId}
                    config={config}
                    setConfig={setConfig}
                    onError={onError}
                    onSuccess={onSuccess}
                />
            </Box>
            <Box mb={3}>
                <StartMatch
                    matchId={matchId}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    onError={onError}
                    onSuccess={onSuccess}
                />
            </Box>
            <Box mb={3}>
                <MatchProblems
                    matchId={matchId} 
                    problems={problems}
                    setProblems={setProblems}
                    onError={onError}
                    onSuccess={onSuccess}
                    reload={reload}
                />
            </Box>
            <Box mb={3}>
                <MatchSubmissions
                    matchId={matchId}
                    contestants={contestants}
                    submissions={submissions}
                    onError={onError}
                    onSuccess={onSuccess}
                    reload={reload}
                />
            </Box>
            <AccessLevelContext.Consumer>
                {accessLevel => accessLevel >= ACCESS_LEVEL_ENUM.SYSTEM_ADMIN &&
                    <ResetMatch
                        matchId={matchId} 
                        onError={onError}
                        onSuccess={onSuccess}
                        reload={reload}
                    />
                }
            </AccessLevelContext.Consumer>
            <Snackbar open={errorOpen} autoHideDuration={2000} onClose={() => setErrorOpen(false)}>
                <Alert onClose={() => setErrorOpen(false)} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={2000} onClose={() => setSuccessOpen(false)}>
                <Alert onClose={() => setSuccessOpen(false)} severity="success">
                    {successMessage}
            </Alert>
            </Snackbar>
        </>
    )
}

export default EditMatchPage;
