import { Route, Switch, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TeamsNavigation from '../../components/teams/TeamsNavigation';
import TeamsGeneral from '../../components/teams/TeamsGeneral';
import TeamsCreatePage from './TeamsCreatePage';
import TeamsJoinPage from './TeamsJoinPage';

const MainTeamsPage = (props) => {

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item>
                <TeamsNavigation />
            </Grid>
            <Grid item >
                <Switch>
                    <Route exact path='/teams/'> <TeamsGeneral /> </Route>
                    <Route exact path='/teams/create'> <TeamsCreatePage /> </Route>
                    <Route exact path='/teams/join'><TeamsJoinPage/></Route>
                    <Route path='*'> <Redirect to='/error' /></Route>
                </Switch>
            </Grid>
        </Grid>
    )
}


export default MainTeamsPage;