import { TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#303339',
            borderRadius: 4,
            '& fieldset': {
                borderColor: '#222428',
                borderWidth: 2
            },
            '&:hover fieldset': {
                borderColor: 'black',
                borderWidth: 2
            },
            '$error &:hover fieldset': {
                borderColor: 'red',
                borderWidth: 2
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0b92c7',
                borderWidth: 4
            },
            
        }
    },
    errorRoot: {
        '& .MuiOutlinedInput-root': {
            borderColor: 'red',
            borderRadius: 4,
        }
    },
}))

const InputText = (props) => {
    const classes = useStyles();
    const { className, children, ...other } = props;
    return <TextField
    variant="outlined"
    className={`${props.error ? classes.errorRoot : classes.root} ${className}`}
    inputProps={{style: {boxShadow: "0 0"}}}
    {...other}> {children} </TextField>
}
export default InputText;