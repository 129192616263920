import { Grid } from '@material-ui/core';
import AnnouncementsCard from '../components/home/AnnouncementsCard';
import TournamentsCard from '../components/home/TournamentsCard';
import UpcomingCard from '../components/home/UpcomingCard';
import NotificationsCard from '../components/home/NotificationsCard';

const HomePage = props => {

    return (
        <Grid container direction="row" spacing={3}>
            <Grid item md={8} xs={12}>
                <AnnouncementsCard />
            </Grid>
            <Grid item md={4} xs={12}>
                <Grid container direction="column" alignItems='stretch' justify='flex-start' spacing={3}>
                    <Grid item xs={12}>
                        <TournamentsCard />
                    </Grid>

                    <Grid item xs={12}>
                        <UpcomingCard />
                    </Grid>

                    <Grid item xs={12}>
                        <NotificationsCard />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HomePage;
