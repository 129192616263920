import InputText from '../components/UI/InputText'
import TextButton from '../components/UI/TextButton'
import InputLabel from '../components/UI/InputLabel'
import useLocalStorage from '../hooks/useLocalStorage'
import { makeStyles, Grid, Paper, Typography, Divider, Box, Button } from '@material-ui/core'
import { useRef, useState } from 'react'


const useStyles = makeStyles({
    root: {
        padding: '20px',
        height: '420px'
    }
})

const hideEmail = (email) => {
    const pos = email.indexOf('@');
    return '*'.repeat(pos) + email.substr(pos);
}

const SettingsPage = (props) => {
    const user = useLocalStorage('user')[0];
    const classes = useStyles();

    return (
        <Grid container spacing={3} direction='column'>
            <Grid item>
                <Paper className={classes.root} elevation={6}>
                    <AccountDetails user={user} />
                </Paper>
            </Grid>

            <Grid item>
                <Paper className={classes.root} elevation={6}>
                    <PasswordAuthentication username={user.username} />
                </Paper>
            </Grid>
        </Grid>
    )
}

const AccountDetails = (props) => {
    const [emailHidden, setEmailHidden] = useState(true);

    const onReveal = () => {
        setEmailHidden(prev => prev === true ? false : true);
    }

    return (
        <>
            <Typography variant='h6'> Account Details </Typography>
            <Divider />
            <Box pl={1} pr={1} pt={2}>

                <Box mb={2}>
                    <InputLabel> Username </InputLabel>
                    <Typography style={{ color: 'white' }}> {props.user.username} </Typography>
                </Box>

                <Box mb={2}>
                    <InputLabel> Name </InputLabel>
                    <Typography style={{ color: 'white' }}> {props.user.name} </Typography>
                </Box>

                <Box mb={2}>
                    <InputLabel> E-mail </InputLabel>
                    <Typography style={{ color: 'white' }}> {emailHidden ? hideEmail(props.user.email) : props.user.email}
                        <TextButton onClick={onReveal}>Reveal</TextButton>
                    </Typography>
                </Box>

                <Box mb={2}>
                    <InputLabel> School </InputLabel>
                    <Typography style={{ color: 'white' }}> {props.user.school} </Typography>
                </Box>

                <Box mb={2}>
                    <InputLabel> Codeforces handle </InputLabel>
                    <Typography style={{ color: 'white' }}> {props.user.codeforcesHandle} </Typography>
                </Box>
            </Box>
        </>
    );
}

const PasswordAuthentication = (props) => {
    const oldRef = useRef();
    const newRef = useRef();
    const confirmNewRef = useRef();

    const [oldErr, setOldErr] = useState(null);
    const [newErr, setNewErr] = useState(null);
    const [confirmNewErr, setConfirmNewErr] = useState(null);
    const [passwordButtonText, setPasswordButtonText] = useState("Change password");

    const onChangePassword = async (e) => {
        e.preventDefault();
        setOldErr(null);
        setNewErr(null);
        setConfirmNewErr(null);
        const oldPassword = oldRef.current.value.trim();
        const newPassword = newRef.current.value.trim();
        const confirmNewPassword = confirmNewRef.current.value.trim();
        if (oldPassword.length === 0) {
            setOldErr('password cannot be empty');
        }
        if (newPassword.length === 0) {
            setNewErr('password cannot be empty');
        }
        if (newPassword !== confirmNewPassword) {
            setConfirmNewErr('The entered passwords are different');
        }
        if (oldErr || newErr || confirmNewErr) return;
        setPasswordButtonText("Loading");
        const response = await fetch(`/api/user/update-password/`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                oldPassword,
                newPassword
            })
        });
        if (response.status === 200) {
            const json = await response.json();
            if (json.status === "OK") {
                setPasswordButtonText("Updated");
            } else {
                setOldErr("The entered password is incorrect");
            }
        } else {
            setOldErr("An unexpected error occured, please try again later");
        }
        setTimeout(() => {
            setPasswordButtonText("Change password");
        }, 2000);
    }

    return (
        <>
            <Typography variant='h6'> Password </Typography>
            <Divider />
            <Box pl={1} pr={1} pt={2} pb={2}>
                <form>
                    <Box mb={2}>
                        <InputLabel> Current Password </InputLabel>
                        <InputText fullWidth size='small' type='password' error={oldErr !== null} helperText={oldErr} inputRef={oldRef} />
                    </Box>

                    <Box mb={2}>
                        <InputLabel> New Password </InputLabel>
                        <InputText fullWidth size='small' type='password' error={newErr !== null} helperText={newErr} inputRef={newRef} />
                    </Box>

                    <Box mb={4}>
                        <InputLabel> Confirm Password </InputLabel>
                        <InputText fullWidth size='small' type='password' error={confirmNewErr !== null} helperText={confirmNewErr} inputRef={confirmNewRef} />
                    </Box>

                    <Button type='submit' color='primary' variant='contained' onClick={onChangePassword}> {passwordButtonText} </Button>
                </form>
            </Box>
        </>
    );
}


export default SettingsPage;
