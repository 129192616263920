import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%'
    }
})



const TeamsNavigation = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                This page is used for team registration procedures.<br/>
                <br/>
                To create a team,<br/>
                <br/>
                1. Both users must first have a verified account.<br/>
                <br/>
                2. One of the team members should head to the "Create Team" tab and fill in all the details.<br/>
                <br/>
                3. After clicking the "Register" button with all correct information filled in, you will be redirected back to the main page and your team invite will be sent to the other user.<br/> 
                <br/>
                4. The other user should go to the "Joined & Invited Teams" tab and accept the invite under the "Active Invitations" list. <br/>
                <br/>
                5. Wait patiently as the administrators verify your team account. You can check the verification status at the "Joined Teams" list under the "Joined & Invited Teams" tab.
            </CardContent>
        </Card>
    )
}

export default TeamsNavigation;