import { Box, Button, Paper, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

function ResetMatch(props) {
    const { matchId } = props;

    const resetMatch = async () => {
        let res = await fetch(`/api/reset-match/${matchId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        });
        if (res.status === 401) {
            props.onError("Unauthorized");
            return;
        }
        if(res.status === 200) {
            const result = await res.json();
            if (result.status === "FAILED") {
                let message = '';
                if (result.body.error) {
                    if (message.length > 0) message = message + '\n';
                    message = message + result.body.error;
                }
                props.onError(message);
            } else {
                props.onSuccess("Match reset successfully");
                props.reload();
            }
            return;
        }
    }

    const onReset = async () => {
        resetMatch();
    }

    return (
        <>
            <Paper elevation={6}>
                <List>
                    <ListItem divider>
                        <ListItemIcon>
                            <ClearIcon />
                        </ListItemIcon>
                        <ListItemText styles={{"color":"white"}} primary='Reset Match'/>
                    </ListItem>
                    <ListItem>
                        <Box width="100%">
                            <Button variant="contained" color="secondary" onClick={onReset}>Reset Match</Button>
                        </Box>
                    </ListItem>
                </List>
            </Paper>
        </>
    )
}

export default ResetMatch;