import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        color: '#04a4e3',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
});

const TextButton = (props) => {
    const classes = useStyles();
    const { className, children, ...other } = props;
    return <span className={`${classes.root} ${className}`} {...other}> {children} </span>
}

export default TextButton;