import RegisterForm from '../components/auth/RegisterForm'
import { Grid, makeStyles, Paper } from '@material-ui/core';
import Logo from '../components/UI/Logo';
import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { hiddenSchools } from '../constants';

const useStyles = makeStyles({
    root: {
        padding: '30px',
        paddingRight: '5px',
        maxWidth: '90vw',
        width: '600px',
        borderRadius: '10px'
    }
});

const LoginPage = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [schools, setSchools] = useState([]);

    const getSchools = useCallback(async () => {
        return await fetch('/auth/get-verified-schools')
            .then(response => {
                if (response.status !== 200)
                    history.push('/error', { error: 'Troll' });
                return response.json();
            })
            .then(response => {
                if (response.status === "FAILED")
                    history.push('/error', { error: response.body.errorMessages.join() });
                return response.body.schools.filter(school => (hiddenSchools.indexOf(school) === -1));
            })
    }, [history]);

    useEffect(() => {
        getSchools().then(schools => {
            setSchools(schools);
        })
    }, [getSchools]);

    return (
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
            <Grid item>
                <Logo />
            </Grid>
            <Grid item>
                <Paper elevation={6} className={classes.root} >
                    <RegisterForm schools={schools} />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default LoginPage;

