import { useRef, useState, useEffect } from 'react';
import { Paper, Box, Grid, Button, Typography, Divider, MenuItem } from '@material-ui/core';
import InputText from '../../UI/InputText';
import InputLabel from '../../UI/InputLabel';
import { useParams } from 'react-router';

import stringToDate from '../../../utils/stringToDate';
import dateToString from '../../../utils/dateToString';

function ConfigCard(props) {
    const { config } = props;
    const { tournamentId } = useParams();
    const nameRef = useRef();
    const regStartRef = useRef();
    const regEndRef = useRef();
    const minDiffRef = useRef();
    const maxDiffRef = useRef();
    const durationRef = useRef();
    const [doubleElimination, setDoubleElimination] = useState(false);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        if (config) {
            setDoubleElimination(config.doubleElimination);
            setHidden(config.hidden);
        }
    }, [config])
    const onChangeTournamentFormat = (event) => {
        setDoubleElimination(event.target.value);
    }

    const onChangeHidden = (event) => {
        setHidden(event.target.value);
    }

    const onCreateTournament = () => {
        const name = nameRef.current.value.trim();
        const regStartTime = regStartRef.current.value.trim();
        const regEndTime = regEndRef.current.value.trim();
        const minDifficulty = minDiffRef.current.value.trim();
        const maxDifficulty = maxDiffRef.current.value.trim();
        const duration = durationRef.current.value.trim();

        // check if everything is filled in
        if (name.length === 0 ||
            regStartTime.length === 0 ||
            regEndTime.length === 0 ||
            minDifficulty.length === 0 ||
            maxDifficulty.length === 0 ||
            duration.length === 0) {
            props.setErrorOpen(true);
            props.setErrorMessage("All fields must be filled");
            return;
        }

        if (!stringToDate(regStartTime)) {
            props.setErrorOpen(true);
            props.setErrorMessage("Registration start time invalid");
            return;
        }
        if (!stringToDate(regEndTime)) {
            props.setErrorOpen(true);
            props.setErrorMessage("Registration end time invalid");
            return;
        }
        if ((+minDifficulty).toString() !== minDifficulty || +minDifficulty < 0) {
            props.setErrorOpen(true);
            props.setErrorMessage("Minimum Difficulty invalid");
            return;
        }
        if ((+maxDifficulty).toString() !== maxDifficulty || +maxDifficulty < 0) {
            props.setErrorOpen(true);
            props.setErrorMessage("Maximum Difficulty invalid");
            return;
        }
        if (+minDifficulty > +maxDifficulty) {
            props.setErrorOpen(true);
            props.setErrorMessage("Min difficulty should not be greater than max difficulty");
            return;
        }
        if ((+duration).toString() !== duration || duration < 0) {
            props.setErrorOpen(true);
            props.setErrorMessage("Duration invalid");
            return;
        }

        fetch(`/api/tournament/${tournamentId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name,
                regStartTime: stringToDate(regStartTime),
                regEndTime: stringToDate(regEndTime),
                minDifficulty: +minDifficulty,
                maxDifficulty: +maxDifficulty,
                duration: +duration,
                doubleElimination,
                hidden,
            }),
            credentials: 'include',
        })
            .then(response => {
                return response.json();
            })
            .then(result => {
                if (result.status === "FAILED") {
                    props.setErrorOpen(true);
                    props.setErrorMessage(result.body.errorMessages.join());
                } else {
                    props.setSuccessOpen(true);
                    props.setSuccessMessage("Tournament updated successfully");
                    props.getConfig();
                    props.getTournament();
                }
            })
            .catch(err => {
                props.setErrorOpen(true);
                props.setErrorMessage(err.toString());
            });
    }

    if (!props.config) return null;
    return (
        <Paper elevation={6}>
            <Box p={4}>
                <Box mb={3}>
                    <Typography variant='h6'> Tournament Config </Typography>
                    <Divider />
                </Box>
                <Grid container spacing={3}>
                    <AttrField label='Name' defaultValue={props.config.name} inputRef={nameRef} />
                    <AttrField label='Registration Start Time' defaultValue={dateToString(props.config.regStartTime)} inputRef={regStartRef} />
                    <AttrField label='Registration End Time' defaultValue={dateToString(props.config.regEndTime)} inputRef={regEndRef} />
                    <AttrField label='Min Difficulty' defaultValue={props.config.minDifficulty} inputRef={minDiffRef} />
                    <AttrField label='Max Difficulty' defaultValue={props.config.maxDifficulty} inputRef={maxDiffRef} />
                    <AttrField label='Duration (in minutes)' defaultValue={props.config.duration} inputRef={durationRef} />
                    <Grid item xs={12} md={4}>
                        <InputLabel> Tournament Format </InputLabel>
                        <InputText select fullWidth value={doubleElimination} onChange={onChangeTournamentFormat}>
                            <MenuItem value={false}> Single Elimination </MenuItem>
                            <MenuItem value={true}> Double Elimination </MenuItem>
                        </InputText>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputLabel> Hidden </InputLabel>
                        <InputText select fullWidth value={hidden} onChange={onChangeHidden}>
                            <MenuItem value={false}> False</MenuItem>
                            <MenuItem value={true}> True </MenuItem>
                        </InputText>
                    </Grid>
                    <Grid item xs={12} >
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={onCreateTournament}
                        > Save </Button>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}

function AttrField(props) {
    const { label, ...rest } = props;
    return <Grid item xs={12} md={4}>
        <InputLabel> {label} </InputLabel>
        <InputText fullWidth {...rest} />
    </Grid>
}

export default ConfigCard;