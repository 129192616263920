import { Paper, Box, Typography, Grid, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import AddIcon from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useEffect, useState, useCallback } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { ACCESS_LEVEL_ENUM } from '../constants';
import { AccessLevelContext } from '../components/PrivateRoute';
import TournamentsTable from '../components/tournaments-list/TournamentsTable';
import CreateTournamentCard from '../components/tournaments-list/CreateTournamentCard';

function TournamentsListPage(props) {
    const [tournaments, setTournaments] = useState([]);
    const getTournaments = useCallback(async () => {
        const response = await fetch(`/api/tournaments`, { credentials: 'include' });
        return (await response.json()).body.tournaments;
    }, []);
    useEffect(() => {
        getTournaments().then(tournaments => {
            setTournaments(tournaments.sort((a, b) => {
                if(a.regEndTime != b.regEndTime) {
                    return b.regEndTime - a.regEndTime;
                }
                return a.id - b.id;
            }));
        });
    }, [getTournaments]);
    return (
        <>
            <Grid container direction='column' spacing={3}>
                <Grid item xs={12}>
                    <PageTitle />
                </Grid>
                <Grid item xs={12}>
                    <Switch>
                        <Route exact path='/tournaments/add'> <CreateTournamentCard /> </Route>
                        <Route path='*'> <TournamentsTable tournaments={tournaments} /> </Route>
                    </Switch>
                </Grid>
            </Grid>
        </>
    )
}

function PageTitle(props) {

    return (
        <Paper elevation={6}>
            <Box overflow='hidden'>
                <ListItem>
                    <ListItemIcon>
                        <TrophyIcon fontSize='large' />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant='h5'> Tournaments </Typography>} />
                    <AccessLevelContext.Consumer>
                        {accessLevel => accessLevel === ACCESS_LEVEL_ENUM.SYSTEM_ADMIN &&
                            <>
                                <Route exact path='/tournaments/add'>
                                    <IconButton component={Link} to='/tournaments'>
                                        <ArrowBack />
                                    </IconButton>
                                </Route>
                                <Route exact path='/tournaments'>
                                    <IconButton component={Link} to='/tournaments/add'>
                                        <AddIcon />
                                    </IconButton>
                                </Route>
                            </>
                        }
                    </AccessLevelContext.Consumer>
                </ListItem>
            </Box>
        </Paper>
    )
}


export default TournamentsListPage;