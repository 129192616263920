import { useState, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

function RegisterButton(props) {
    const [open, setOpen] = useState(false);

    const onRegister = useCallback((registerStatus) => {
        if (registerStatus === "Registered") return;
        if (registerStatus === "Not registered" ||
            registerStatus === "Waiting for verification") {
            setOpen(true);
        }
    }, []);

    const onAgreeRegister = useCallback(async (registerStatus) => {
        if (registerStatus === "Not registered") {
            await fetch(`/api/tournament/${props.tournamentId}/register`, { method: 'POST', credentials: 'include'});
        } else if (registerStatus === "Waiting for verification") {
            await fetch(`/api/tournament/${props.tournamentId}/unregister`, { method: 'POST', credentials: 'include'});
        }
        setOpen(false);
        props.getTournament();
    }, [props]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                disabled={props.registerStatus === "Registered"}
                onClick={onRegister.bind(this, props.registerStatus)}
            >
                {props.registerStatus === "Waiting for verification" ? "Unregister" : "Register!"}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle> Are you sure? </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.registerStatus === "Not registered" && "Your registration will be confirmed after verification by admin. Note that you will NOT be able to withdraw from the contest after your registration is accepted."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" autoFocus>
                        Not really
                    </Button>
                    <Button onClick={onAgreeRegister.bind(this, props.registerStatus)} color="primary" autoFocus>
                        I am sure
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default RegisterButton;