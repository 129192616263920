import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%'
    }
})



const AdminNavigation = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                Hello
            </CardContent>
        </Card>
    )
}

export default AdminNavigation;