import usePageInit from '../hooks/usePageInit';
import ProfileCard from '../components/profile/ProfileCard';
import TeamProfileCard from '../components/profile/TeamProfileCard';
import { useLocation, useParams } from 'react-router';
import { useEffect } from 'react';
import HistoryCard from '../components/profile/HistoryCard';
import Grid from '@material-ui/core/Grid';
import useLocalStorage from '../hooks/useLocalStorage';
import { CardContent, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    notFoundCard: {
        paddingBottom: '20px',
        paddingTop: '20px'
    }
})

const ProfilePage = (props) => {
    const classes = useStyles();
    const location = useLocation();
    const self = useLocalStorage('user', null)[0];
    const { username } = useParams();
    const [response, reload] = usePageInit(`/api/user/${username ? username : self.username}`, 'GET');
    useEffect(() => {
        reload();
    }, [location.pathname, reload])

    if (!response) return null;
    if (response.status === "FAILED") {
        return (
            <Card>
                <CardContent className={classes.notFoundCard}>
                    <Typography variant='h6'> Error: User does not exist </Typography>
                </CardContent>
            </Card>
        )
    }
    if (response.body.user.isTeam==='TEAM'){
        return (
            <Grid container spacing={3} direction="column">
                <Grid item xs={12}> <TeamProfileCard user={response.body.user} /></Grid>
                <Grid item xs={12}> <HistoryCard matches={response.body.matches} tournaments={response.body.tournaments} /></Grid>
            </Grid>
        ) 
    }
    else{
        return (
            <Grid container spacing={3} direction="column">
                <Grid item xs={12}> <ProfileCard user={response.body.user} /></Grid>
                <Grid item xs={12}> <HistoryCard matches={response.body.matches} tournaments={response.body.tournaments} /></Grid>
            </Grid>
        ) 
    }
    
}

export default ProfilePage;