import { Grid, makeStyles, Button, Typography} from '@material-ui/core';
import InputText from '../UI/InputText';
import InputLabel from '../UI/InputLabel';
import { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import useLocalStorage from '../../hooks/useLocalStorage'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: 0
    },
    registerButton: {
        width: '100%',
        height: '50px',
        textTransform: 'none',
        fontSize: '18px',
        marginRight: 0,
        marginBottom: '10px'
    },
    verifyButton: {
        width: '100%',
        height: '50px'
    },
    title: {
        fontWeight: 'bold'
    },
    loginLink: {
        textDecoration: 'none'
    },
    emailLoading: {
        color: 'white'
    }
}));



const TeamRegisterForm = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const fields = ['username','createUser', 'invitedUser', 'password', 'confirmPassword'];
    const formAttr = {
        username: {
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'Your Team Name',
            id: 'username'
        },
        createUser:{
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'Your Username',
            id: 'createUser'
        },
        invitedUser:{
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'Invited User',
            id: 'invitedUser'
        },
        password: {
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'Password',
            id: 'password'
        },
        confirmPassword: {
            ...useState(null).reduce((res, val, idx) => {
                res[idx === 0 ? 'error' : 'setError'] = val;
                return res;
            }, {}),
            ref: useRef(),
            label: 'Re-enter password',
            id: 'confirmPassword'
        }
    }

    const self = useLocalStorage('user', null)[0];

    const onRegister = async (e) => {
        e.preventDefault();

        let haveError = false;
        fields.forEach(field => {
            formAttr[field].setError(null);
        });

        fields.forEach(field => {
            if (formAttr[field].ref.current.value.trim() === '') {
                formAttr[field].setError(`${formAttr[field].label} cannot be empty`);
                haveError = true;
            }
        });

        
        const formData = fields.reduce((res, val) => {
            res[val] = formAttr[val].ref.current.value.trim();
            return res;
        }, {});

        if (formData.password !== formData.confirmPassword) {
            formAttr['confirmPassword'].setError('Entered passwords are not the same');
            haveError = true;
        }

        if (formData.createUser === formData.invitedUser){
            formAttr['invitedUser'].setError('You cannot invite yourself');
            haveError = true;
        }

        if (formData.createUser !== self.username){
            formAttr['createUser'].setError('This is not your username');
            haveError = true;
        }

        if (haveError) return;

        delete formData.confirmPassword;

        const fetchOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        }

        const request = await fetch(`/auth/team-registration`, fetchOptions);
        if (request.status !== 200) {
            console.log('An error occured, please try again later');
            return;
        }

        const response = await request.json();
        if (response.status === "FAILED") {
            const used = response.body.error.used;
            if (used) {
                used.forEach(field => {
                    formAttr[field].setError(`${formAttr[field].label} taken`)
                })
            }
            const isTeam = response.body.error.isTeam;
            if (isTeam){
                isTeam.forEach(field =>{
                    formAttr[field].setError(`Invalid user`)
                })
            }
            const created = response.body.error.created;
            if (created){
                created.forEach(field =>{
                    formAttr[field].setError(`Team has been created before`);
                })
            }
        } else {
            history.push("/")
        }
    }

    return (
        <form className={classes.root} onSubmit={onRegister}>
            <Grid container direction="column" spacing={3} className={classes.root}>
                <Grid item xs={12}>
                    <Typography align='center' variant="h4" className={classes.title}>  Create a team  </Typography>
                </Grid>

                {['username','createUser','invitedUser'].map((field) =>
                    <Grid item xs={12} key={field}>
                        <AttrField formAttr={formAttr} field={field} />
                    </Grid>
                )}

                {['password', 'confirmPassword'].map((field) =>
                    <Grid item xs={12} key={field}>
                        <AttrField formAttr={formAttr} field={field} type='password' />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.registerButton}
                        type="submit"
                    >
                        Register
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

// Form field for everything except school and email
const AttrField = (props) => {
    const { field, formAttr, type } = props;
    return (
        <>
            <InputLabel> {formAttr[field].label} </InputLabel>
            <InputText
                id={formAttr[field].id}
                fullWidth inputRef={formAttr[field].ref}
                error={formAttr[field].error ? true : false}
                helperText={formAttr[field].error}
                type={type}
            />
        </>
    );
}

export default TeamRegisterForm;