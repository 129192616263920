import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import usePageInit from '../../hooks/usePageInit';
import UserDetails from '../../components/teams/TeamDetails';
import useLocalStorage from '../../hooks/useLocalStorage';

const useStyles = makeStyles({
    userListAccordionGrid: {
        padding: '20px',
        paddingTop: '0'
    },
    userAccordion: {
        background: '#2f3136'
    }
})

const TeamsJoinPage = (props) => {
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false);
    const [response, reload] = usePageInit('/api/admin/teams', 'GET');
    const [schools, setSchools] = useState(null);
    const [users, setUsers] = useState(null);
    const [inviteAccepted, setInviteAccepted] = useState(null);
    const [modifiedUsers, setModifiedUsers] = useState({});

    useEffect(() => {
        if (!response) return;
        setSchools(response.body.schools);
        setUsers(response.body.users);
        setInviteAccepted(response.body.inviteAccepted);
        setModifiedUsers(JSON.parse(JSON.stringify(response.body.users)));
    }, [response]);

    if (!response || !schools || !users || !inviteAccepted || !modifiedUsers) return null;

    const onError = (message) => {
        setErrorMessage(message);
        setErrorOpen(true);
    }

    const onSuccess = () => {
        setSuccessOpen(true);
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                    <Grid container direction='column' spacing={3}>
                    <Grid item>
                            <UserListAccordion
                                users={users}
                                modifiedUsers={modifiedUsers}
                                setUsers={setUsers}
                                setModifiedUsers={setModifiedUsers}
                                onError={onError}
                                onSuccess={onSuccess}
                                reload={reload}
                                inviteAccepted={inviteAccepted}
                                isAccepted={false}
                                pending={true}
                            />
                        </Grid>

                        <Grid item>
                            <UserListAccordion
                                users={users}
                                modifiedUsers={modifiedUsers}
                                setUsers={setUsers}
                                setModifiedUsers={setModifiedUsers}
                                onError={onError}
                                onSuccess={onSuccess}
                                reload={reload}
                                inviteAccepted={inviteAccepted}
                                isAccepted={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar open={errorOpen} autoHideDuration={2000} onClose={() => setErrorOpen(false)}>
                <Alert onClose={() => setErrorOpen(false)} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={2000} onClose={() => setSuccessOpen(false)}>
                <Alert onClose={() => setSuccessOpen(false)} severity="success">
                    User saved successfully
            </Alert>
            </Snackbar>
        </>
    )
}

const UserListAccordion = (props) => {
    const classes = useStyles();
    const self = useLocalStorage('user', null)[0];

    return (
        <Accordion elevation={6} key={2} expanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'> {props.pending?'Active Invitations': 'Joined Teams'} </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction='column' spacing={2} padding={3} className={classes.userListAccordionGrid}>
                    {props.users.map((user, userIndex) => {
                        if (user.invitedUser !== self.username && user.createUser !== self.username) return null;
                        if (user.createUser === self.username && user.inviteAccepted === false && !props.pending) return null;
                        if (user.inviteAccepted !== props.isAccepted) return null;

                        return (<Accordion className={classes.userAccordion} key={`${user.username}-accordion`}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography variant='subtitle1'> {user.username} </Typography>
                            </AccordionSummary>
                            <AccordionDetails key={`${user.username}-accordion-details`}>
                                <UserDetails
                                    key={`${user.username}-details`}
                                    userIndex={userIndex}
                                    {...props}
                                />
                            </AccordionDetails>
                        </Accordion>);
                    })}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default TeamsJoinPage;