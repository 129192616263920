import { useHistory } from 'react-router-dom';
import { useState, useEffect, createContext } from 'react';

const AccessLevelContext = createContext(null);

const PrivateRoute = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [accessLevel, setAccessLevel] = useState(null);
    const history = useHistory();

    useEffect(() => {
        async function main() {
            const fetchOptions = {
                method: 'GET',
                credentials: 'include'
            }
            const request = await fetch(`/auth/minimal`, fetchOptions);

            if (request.status !== 200) {
                const message = await request.text();
                if (message === 'Unauthorized') {
                    history.push('/login');
                    return;
                }
                if (message === 'No access') {
                    setLoaded(true);
                    history.push('/error', { message: 'Page not found' });
                    return;
                }
                history.push('/error', { message: message.length !== 0 ? message : 'Unexpected Error' });
                return;
            }
            
            const response = await request.json();
            setAccessLevel(response.accessLevel);
            setLoaded(true);
            return;
        }
        main();
    }, [history, props])

    if (!loaded) return null;

    return (
        <AccessLevelContext.Provider value={accessLevel}>
            {props.children}
        </AccessLevelContext.Provider>
    )
}

export { PrivateRoute as default, AccessLevelContext };