import { darken, Paper, ListItem, ListItemIcon, ListItemText, Typography, makeStyles, List } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ContestantsIcon from '@material-ui/icons/People';

const useStyles = makeStyles(theme => ({
    contestantList: {
        padding: 0
    },
    contestantListItem: {
        '&:hover': {
            backgroundColor: darken(theme.palette.background.paper, 0.2)
        },
    }
}));

function ContestantsCard(props) {
    const classes = useStyles();

    return (
        <Paper elevation={6}>
            <List className={classes.contestantList}>
                <ListItem divider>
                    <ListItemIcon>
                        <ContestantsIcon />
                    </ListItemIcon>
                    <ListItemText size="xl" primary={<Typography variant='h6'> Contestants </Typography>} />
                </ListItem>
                {props.contestants.map(contestant =>
                    <ListItem key={contestant} button divider component={Link} to={`/profile/${contestant}`} className={classes.contestantListItem}>
                        <ListItemText primary={contestant} />
                    </ListItem>
                )}
            </List>
        </Paper>
    )
}

export default ContestantsCard;