import { useState } from 'react';
import { Grid, Box, Button, Switch, FormControlLabel, Table, TableBody, TableRow, TableHead, TableCell, Paper, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import InputText from '../UI/InputText';
import TaskIcon from '@material-ui/icons/Assignment';

function MatchProblems(props) {
    const { matchId, problems } = props;
    const [filterSolvedProblems, setFilterSolvedProblems] = useState(true);

    const shuffleProblems = async (tasks) => {
        let params = new URLSearchParams();
        params.set("includeSolvedTasks", !filterSolvedProblems);
        let res = await fetch(`/api/shuffle-task/${matchId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                includeSolvedTasks: !filterSolvedProblems,
                tasks
            }),
            credentials: 'include'
        });
        if (res.status === 401) {
            props.onError("Unauthorized");
            return;
        }
        if(res.status === 200) {
            const result = await res.json();
            if (result.status === "FAILED") {
                props.onError(result.body.error);
            } else {
                props.onSuccess("Problem shuffled successfully");
                props.reload();
            }
            return;
        }
    }

    const setProblem = async (id, contestId, taskIndex) => {
        let res = await fetch(`/api/set-task/${matchId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id,
                task: {
                    contestId: +contestId,
                    taskIndex
                }
            }),
            credentials: 'include'
        });
        if (res.status === 401) {
            props.onError("Unauthorized");
            return;
        }
        if(res.status === 200) {
            const result = await res.json();
            if (result.status === "FAILED") {
                props.onError(result.body.error);
            } else {
                props.onSuccess("Problems shuffled successfully");
                props.setProblems(prev => {
                    const tmp = prev;
                    tmp[id].newContestId = "";
                    tmp[id].newTaskIndex = "";
                    return tmp;
                });
                props.reload();
            }
            return;
        }
    }

    const onShuffleAll = () => {
        shuffleProblems([0, 1, 2, 3, 4, 5, 6, 7, 8]);
    }

    const onEditProblem = (id) => () => {
        setProblem(id, problems[id].newContestId, problems[id].newTaskIndex);
    }

    const onShuffleProblem = (id) => () => {
        shuffleProblems([id]);
    }

    const onChange = (id) => (event) => {
        props.setProblems(prev => {
            const tmp = [...prev];
            tmp[id][event.target.name] = event.target.value;
            return tmp;
        });
    }

    return (
        <>
            <Paper elevation={6}>
                <List>
                    <ListItem divider>
                        <ListItemIcon>
                            <TaskIcon />
                        </ListItemIcon>
                        <ListItemText styles={{"color":"white"}} primary='Problems'/>
                    </ListItem>
                    <ListItem>
                        <Box mt={2} width="100%">
                            <Box mb={2}>
                                <FormControlLabel
                                    control={<Switch color="primary" checked={filterSolvedProblems} onChange={() => setFilterSolvedProblems(!filterSolvedProblems)} />}
                                    label="Filter Solved Problems" />
                            </Box>
                            <Box mb={2}>
                                <Button variant="contained" color="primary" onClick={onShuffleAll}>Shuffle All Problems</Button>
                            </Box>
                            <ProblemsTable>
                                {problems.map((problem, index) => (
                                    <ProblemsTableRow key={index} index={index}
                                    onChange={onChange} onEditProblem={onEditProblem} onShuffleProblem={onShuffleProblem(index)} {...problem}/>
                                ))}
                            </ProblemsTable>
                        </Box>
                    </ListItem>
                </List>
            </Paper>
        </>
    )
}

function ProblemsTable(props) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Problem ID</TableCell>
                    <TableCell>Edit Problem ID</TableCell>
                    <TableCell>Shuffle Problem</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.children}
            </TableBody>
        </Table>
    )
}

function ProblemsTableRow(props) {
    return (
        <TableRow>
            <TableCell>
                <a
                    href={`https://codeforces.com/problemset/problem/${props.contestId}/${props.taskIndex}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{"color":"inherit"}}
                >
                    {props.contestId+props.taskIndex} ({props.difficulty})
                </a>
            </TableCell>
            <TableCell>
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <InputText placeholder="Contest ID" value={props.newContestId} name="newContestId" onChange={props.onChange(props.index)}/>
                    </Grid>
                    <Grid item>
                        <InputText placeholder="Problem Index" value={props.newTaskIndex} name="newTaskIndex" onChange={props.onChange(props.index)}/>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={props.onEditProblem(props.index)}>Save</Button>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Button variant="contained" color="primary" onClick={props.onShuffleProblem}>Shuffle</Button>
            </TableCell>
        </TableRow>
    )
}

export default MatchProblems;
