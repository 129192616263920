import { Paper, Box, Typography, Divider, List, ListItem, ListItemText, makeStyles, darken, Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    contestantListItemPaper: {
        background: darken(theme.palette.background.paper, 0.3),
        marginTop: '10px'
    }
}));

function VerifyContestantsCard(props) {
    if (!props.config) return null;
    return (
        <Paper elevation={6}>
            <Box p={4}>
                <Typography variant='h6' gutterBottom> Unverified Contestants </Typography>
                <Divider />
                <List>
                    {props.config.unverifiedContestants.map(contestant => <ContestantListItem key={contestant} contestant={contestant} {...props} />)}
                </List>
                <Typography variant='h6' gutterBottom> Verified Contestants </Typography>
                <Divider />
                <List>
                    {props.config.verifiedContestants.map(contestant => <ContestantListItem key={contestant} verified contestant={contestant} {...props} />)}
                </List>
            </Box>
        </Paper>
    )
}

function ContestantListItem(props) {
    const { tournamentId } = useParams();
    const classes = useStyles();

    const onToggleVerify = async (contestant) => {
        await fetch(`/api/tournament/${tournamentId}/${props.verified ? 'unverify' : 'verify'}-contestant`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ username: contestant }),
        });
        props.setSuccessOpen(true);
        props.setSuccessMessage(props.verified ? "Contestant verified" : "Contestant unverified");
        props.getConfig();
        props.getTournament();
    }

    const onDelete = async (contestant) => {
        await fetch(`/api/tournament/${tournamentId}/remove-contestant`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ username: contestant }),
        });
        props.setSuccessOpen(true);
        props.setSuccessMessage('Contestant removed');
        props.getConfig();
        props.getTournament();
    }

    return (
        <Paper className={classes.contestantListItemPaper}>
            <ListItem>
                <ListItemText> {props.contestant} </ListItemText>
                <Button color='secondary' onClick={onDelete.bind(this, props.contestant)}> delete </Button>
                <Button color='primary' onClick={onToggleVerify.bind(this, props.contestant)}> {props.verified ? "Unverify" : "Verify"} </Button>
            </ListItem>
        </Paper>
    )
}

export default VerifyContestantsCard;