import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import InputText from '../../UI/InputText';
import InputLabel from '../../UI/InputLabel';
import { ACCESS_LEVEL_ENUM } from '../../../constants';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { AccessLevelContext } from '../../PrivateRoute';

const useStyles = makeStyles({
    root: {
        padding: '20px',
        marginTop: '-40px'
    }
})

const userAttributes = [
    {
        key: 'username',
        label: 'Username'
    },
    {
        key: 'name',
        label: 'Name'
    },
    {
        key: 'school',
        label: 'School'
    },
    {
        key: 'email',
        label: 'E-mail'
    },
    {
        key: 'codeforcesHandle',
        label: 'Codeforces handle'
    }
];

const UserDetails = (props) => {
    const classes = useStyles();
    const { modifiedUsers, setModifiedUsers, users, setUsers, userIndex } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const updateServer = async (updates) => {
        const fetchOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ updates }),
            credentials: 'include'
        }
        const response = await fetch(`/api/admin/user/${users[userIndex].username}`, fetchOptions);
        if (response.status === 401) {
            props.onError('Unauthorized');
            return;
        }
        if (response.status === 200) {
            const result = await response.json();
            if (result.status === "FAILED") {
                let message = '';
                if (result.body.taken.length > 0) {
                    message = message + `${JSON.stringify(result.body.taken)} taken.`;
                }
                if (result.body.invalid.length > 0) {
                    if (message.length > 0) message = message + '\n';
                    message = message + `${JSON.stringify(result.body.invalid)} invalid.`;
                }
                if (result.body.rejected.length > 0) {
                    if (message.length > 0) message = message + '\n';
                    message = message + `${JSON.stringify(result.body.rejected)} cannot be modified.`;
                }
                props.onError(message);
            } else {
                setUsers(prev => {
                    const tmp = [...prev];
                    tmp[userIndex] = JSON.parse(JSON.stringify(modifiedUsers[userIndex]));
                    return tmp;
                })
                props.onSuccess();
            }
            props.reload();
            return;
        }
    }

    const onSave = (userIndex) => async () => {
        const updates = Object.keys(modifiedUsers[userIndex]).reduce((accum, key) => {
            if (modifiedUsers[userIndex][key] !== users[userIndex][key]) {
                accum.push({
                    key,
                    value: modifiedUsers[userIndex][key]
                })
            }
            return accum;
        }, []);
        updateServer(updates);
    }

    const onRevert = () => {
        setModifiedUsers(prev => {
            const tmp = [...prev];
            tmp[userIndex] = JSON.parse(JSON.stringify(users[userIndex]));
            return tmp;
        })
    }

    const onAttributeChange = (attribute) => (event) => {
        setModifiedUsers(prev => {
            const tmp = [...prev];
            tmp[userIndex] = {
                ...tmp[userIndex],
                [attribute]: ((attribute === 'verified' || attribute === 'handleHidden') ? event.target.checked : event.target.value)
            };
            return tmp;
        })
    }

    return (
        <Grid container spacing={3} className={classes.root}>
            <AttributeInputGroup
                onAttributeChange={onAttributeChange}
                {...props}
            />

            <Grid item xs={12}>
                <Grid container spacing={2} direction='row' justify={mobile ? 'center' : 'flex-start'}>
                    <Grid item>
                        <Button fullWidth variant='outlined' style={{borderColor: 'transparent'}} color='primary' onClick={onSave(userIndex)}> Save </Button>
                    </Grid>
                    <Grid item>
                        <Button fullWidth variant='outlined' style={{color: '#e74c3c', borderColor: 'transparent'}} onClick={onRevert}> Revert </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const AttributeInputGroup = (props) => {
    const { userIndex, users, modifiedUsers } = props;

    return (
        <>
            {userAttributes.map((attribute) =>
                <Grid item key={`${users[props.userIndex].username}-${attribute.key}`} xs={12} sm={6} md={4}>
                    <InputLabel> {attribute.label} </InputLabel>
                    <InputText value={modifiedUsers[userIndex][attribute.key]} fullWidth onChange={props.onAttributeChange(attribute.key)} />
                </Grid>
            )}

            <AccessLevelContext.Consumer>
            {accessLevel => accessLevel === ACCESS_LEVEL_ENUM.SYSTEM_ADMIN &&
                // only system admin has permission to modify access leel
                <Grid item key={`${users[userIndex].username}-access-level`} xs={12} sm={6} md={4}>
                    <InputLabel> Access level </InputLabel>
                    <InputText select value={modifiedUsers[userIndex].accessLevel} fullWidth onChange={props.onAttributeChange('accessLevel')}>
                        <MenuItem value={0}> User </MenuItem>
                        <MenuItem value={1}> School Admin </MenuItem>
                        <MenuItem value={2}> System Admin </MenuItem>
                    </InputText>
                </Grid>
            }
            </AccessLevelContext.Consumer>
            

            <Grid item xs={12}>
                <Typography>
                    <Checkbox
                        checked={modifiedUsers[userIndex].verified}
                        onChange={props.onAttributeChange('verified')}
                        color='primary'
                    />
                    Verified
                </Typography>
                <Typography>
                    <Checkbox
                        checked={modifiedUsers[userIndex].handleHidden}
                        onChange={props.onAttributeChange('handleHidden')}
                        color='primary'
                    />
                    Hide Codeforces Handle
                </Typography>
            </Grid>

        </>
    )
}

export default UserDetails;