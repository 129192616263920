import {useState} from 'react';
import {Box, Button} from '@material-ui/core';

const notificationsRequestPermission = () => new Promise(resolve => {
    try {
        Notification.requestPermission().then(permission => resolve(permission));
    }
    catch(e) {
        Notification.requestPermission(permission => resolve(permission));
    }
});

function EnableNotificationsButton(props) {
    const {altRender, ...extraProps} = props;
    const [notificationsPermission, setNotificationsPermission] = useState('Notification' in window ? Notification.permission : 'denied');
    const handleClick = () => {
        notificationsRequestPermission().then(permission => setNotificationsPermission(permission));
    }
    if (!('Notification' in window) || notificationsPermission === 'granted') return altRender??null;
    return (
        <Box {...extraProps}>
            <Button variant="contained" color="primary" onClick={handleClick}>Enable Notifications</Button>
        </Box>
    );
}

function TestNotificationsButton() {
    const handleTestNotificationsClick = () => {
        createNotification("Notification", {
            body: 'Testing Notification',
            icon: process.env.PUBLIC_URL + '/js-icon-sm-trans.ico'
        });
    }
    return (
        <EnableNotificationsButton altRender={
            <Button variant="contained" color="primary" onClick={handleTestNotificationsClick}>Test Notifications</Button>
            } />
    );
}

function createNotification(title, {body, icon, onClick}) {
    if (!('Notification' in window && Notification.permission === 'granted')) return;
    let notification = new Notification(title, {body, icon});
    if (onClick) notification.addEventListener('click', onClick);
}

export default EnableNotificationsButton;
export {TestNotificationsButton, createNotification};
