import {useState} from "react";
import {Paper, List, ListItem, ListItemIcon, ListItemText, Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Grid, MenuItem} from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import dateToString from '../../utils/dateToString';
import InputLabel from '../UI/InputLabel';
import InputText from "../UI/InputText";

function MatchSubmissions(props) {
    const {matchId, contestants, submissions} = props;
    const [submissionInfo, setSubmissionInfo] = useState({
        remoteId: "",
        user: "",
        time: "",
        contestId: "",
        taskIndex: "",
        verdict: ""
    });

    const removeSubmission = async remoteId => {
        let res = await fetch("/api/remove-submission", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                remoteId
            }),
            credentials: "include"
        });
        if (res.status === 401) {
            props.onError("Unauthorized");
            return;
        }
        if (res.status === 200) {
            const result = await res.json();
            if (result.status === "FAILED") {
                props.onError(result.body.error);
            }
            else {
                props.onSuccess("Removed Submission");
                props.reload();
            }
            return;
        }
    };

    const updateSubmission = async () => {
        let res = await fetch(`/api/update-submission/${matchId}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                remoteId: +submissionInfo.remoteId,
                user: submissionInfo.user,
                time: submissionInfo.time,
                contestId: +submissionInfo.contestId,
                taskIndex: submissionInfo.taskIndex,
                verdict: submissionInfo.verdict
            }),
            credentials: "include"
        });
        if (res.status === 401) {
            props.onError("Unauthorized");
            return;
        }
        if (res.status === 200) {
            const result = await res.json();
            if (result.status === "FAILED") {
                props.onError(result.body.error);
            }
            else {
                setSubmissionInfo({
                    remoteId: "",
                    user: "",
                    time: "",
                    contestId: "",
                    taskIndex: "",
                    verdict: ""
                });
                props.onSuccess("Updated Submission");
                props.reload();
            }
            return;
        }
    }

    const handleEdit = submission => () => {
        setSubmissionInfo({
            remoteId: submission.remoteId,
            user: submission.user,
            time: dateToString(submission.time),
            contestId: submission.contestId,
            taskIndex: submission.taskIndex,
            verdict: submission.verdict
        });
    }

    const handleRemove = remoteId => () => {
        removeSubmission(remoteId);
    }

    const handleChange = event => {
        setSubmissionInfo(submissionInfo => {
            let newSubmissionInfo = {...submissionInfo};
            newSubmissionInfo[event.target.name] = event.target.value;
            return newSubmissionInfo;
        });
    }

    const handleUpdate = () => {
        updateSubmission();
    }

    return (
            <Paper elevation={6}>
                <List>
                    <ListItem divider>
                        <ListItemIcon>
                            <ListIcon />
                        </ListItemIcon>
                        <ListItemText styles={{"color":"white"}} primary="Submissions" />
                    </ListItem>
                    <ListItem>
                        <Box width="100%">
                            <Box mb={2}>
                                <SubmissionsTable>
                                    {submissions.map(submission => (
                                        <SubmissionsTableRow key={submission.remoteId} {...submission} onEdit={handleEdit(submission)} onRemove={handleRemove(submission.remoteId)} />
                                    ))}
                                </SubmissionsTable>
                            </Box>
                            <SubmissionInfo contestants={contestants} {...submissionInfo} onChange={handleChange} onUpdate={handleUpdate} />
                        </Box>
                    </ListItem>
                </List>
            </Paper>
    );
}

function SubmissionsTable(props) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="center">Time</TableCell>
                    <TableCell align="center">Username</TableCell>
                    <TableCell align="center">Task ID</TableCell>
                    <TableCell align="center">Verdict</TableCell>
                    <TableCell align="center">Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.children}
            </TableBody>
        </Table>
    );
}

function SubmissionsTableRow(props) {
    return (
        <TableRow>
            <TableCell align="center">{dateToString(props.time)}</TableCell>
            <TableCell align="center">{props.user}</TableCell>
            <TableCell align="center">{props.contestId+props.taskIndex}</TableCell>
            <TableCell align="center">{props.verdict}</TableCell>
            <TableCell align="center">
                <Button variant="contained" color="primary" onClick={props.onEdit}>Edit</Button>
                <Button variant="contained" color="secondary" onClick={props.onRemove}>Remove</Button>
            </TableCell>
        </TableRow>
    );
}

const verdicts = ["OK", "COMPILATION_ERROR", "RUNTIME_ERROR", "WRONG_ANSWER", "PRESENTATION_ERROR", "TIME_LIMIT_EXCEEDED", "MEMORY_LIMIT_EXCEEDED"];

function SubmissionInfo(props) {
    return (
        <>
            <Box mb={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel>Codeforces Submission ID</InputLabel>
                        <InputText value={props.remoteId} name="remoteId" fullWidth onChange={props.onChange} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel>Username</InputLabel>
                        <InputText select value={props.user} name="user" fullWidth onChange={props.onChange}>
                            {props.contestants.map(contestant => <MenuItem value={contestant}>{contestant}</MenuItem>)}
                        </InputText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel>Time</InputLabel>
                        <InputText value={props.time} name="time" fullWidth onChange={props.onChange} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel>Contest ID</InputLabel>
                        <InputText value={props.contestId} name="contestId" fullWidth onChange={props.onChange} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel>Problem Index</InputLabel>
                        <InputText value={props.taskIndex} name="taskIndex" fullWidth onChange={props.onChange} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel>Verdict</InputLabel>
                        <InputText select value={props.verdict} name="verdict" fullWidth onChange={props.onChange}>
                            {verdicts.map(verdict => <MenuItem value={verdict}>{verdict}</MenuItem>)}
                        </InputText>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Button variant="contained" color="primary" onClick={props.onUpdate}>Update</Button>
            </Box>
        </>
    );
}

export default MatchSubmissions;
