import { Link } from "react-router-dom";
import { Table, TableBody, TableRow, TableHead, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    link: {
        color: 'inherit',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none',
            cursor: 'pointer'
        }
    }
});

function FriendlyMatchTable(props) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Match</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.children}
            </TableBody>
        </Table>
    );
}

function FriendlyMatchTableRow(props) {
    const classes = useStyles();
    return (
        <TableRow>
            <TableCell>
                <Link to={"/match/"+props.id} className={classes.link}>{props.id}</Link>
            </TableCell>
            <TableCell>{props.contestant1+" vs "+props.contestant2}</TableCell>
        </TableRow>
    );
}

export {FriendlyMatchTable as default, FriendlyMatchTableRow};
