import { useState, useCallback, useEffect } from 'react';
import { darken, Paper, Typography, Grid, Box, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import FireIcon from '@material-ui/icons/LocalFireDepartment';
import { Link } from "react-router-dom";
import dateToString from '../../utils/dateToString';

const useStyles = makeStyles((theme) => ({
    upcomingMatchList: {
        padding: 0,
    },
    upcomingMatchCell: {
        '&:hover': {
            backgroundColor: darken(theme.palette.background.paper, 0.2)
        },
    }
}));

const UpcomingCards = props => {
    const classes = useStyles();
    const [matches, setMatches] = useState([]);
    const getUpcomingMatches = useCallback(async () => {
        let matches = await fetch(`/api/upcoming-matches`, {
            credentials: 'include'
        }).then(res => res.json());
        return matches;
    }, []);
    useEffect(() => {
        getUpcomingMatches().then(matches => {
            setMatches(matches);
        });
    }, [getUpcomingMatches]);
    return (
        <Paper elevation={6}>
            <Box overflow='hidden'>
                <List className={classes.upcomingMatchList}>
                    <ListItem divider>
                        <ListItemIcon>
                            <FireIcon />
                        </ListItemIcon>
                        <ListItemText primary='Upcoming matches' />
                    </ListItem>
                    {
                        matches.map(match => (
                            <ListItem
                                button
                                divider
                                key={match.id}
                                component={Link}
                                to={"/match/" + match.id}
                                className={classes.upcomingMatchCell}
                            >
                                <Grid container>
                                    <Grid item xs={6}>
                                        {dateToString(match.scheduledStartTime)}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography noWrap>
                                            {match.contestant1 + " vs " + match.contestant2}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))
                    }
                </List>
            </Box>
        </Paper >
    )
}

export default UpcomingCards;