import { makeStyles, lighten } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import LabelIcon from '@material-ui/icons/LabelRounded'
import SchoolIcon from '@material-ui/icons/SchoolRounded';
import CodeforcesIcon from '@material-ui/icons/LeaderboardRounded';
import LaunchIcon from '@material-ui/icons/LaunchRounded';
import TextButton from '../UI/TextButton';
import { hiddenSchools } from '../../constants';

const useStyles = makeStyles({
    avatarButton: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    root: {
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    attribute: {
        paddingBottom: 0
    },
    toCodeforces: {
        marginBottom: -3,
        '&:hover': {
            color: lighten('#04a4e3', 0.4)
        }
    }
});

const ProfileCard = (props) => {
    const classes = useStyles();
    const user = props.user;

    const onToCodeforces = (codeforcesHandle) => () => {
        const newWindow = window.open(`https://codeforces.com/profile/${codeforcesHandle}`, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }


    return (
        <Paper elevation={6}>
            <ListItem divider>
                <ListItemText disableTypography> <Typography variant='h5'> {user.username} </Typography> </ListItemText>
            </ListItem>
                <List>
                    <ListItem className={classes.attribute}>
                        <ListItemIcon> <LabelIcon /></ListItemIcon>
                        <ListItemText disableTypography primary={<Typography variant='subtitle1'> {user.name} </Typography>} />
                    </ListItem>
                    <ListItem className={classes.attribute}>
                        <ListItemIcon> <SchoolIcon /></ListItemIcon>
                        <ListItemText disableTypography primary={<Typography variant='subtitle1'> {hiddenSchools.indexOf(user.school) !== -1 ? '—' : user.school} </Typography>} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon> <CodeforcesIcon /></ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={<Typography variant='subtitle1'> {user.handleHidden ? '—' : user.codeforcesHandle} <TextButton onClick={onToCodeforces(user.handleHidden? 'MikeMirzayanov' : user.codeforcesHandle)}> <LaunchIcon className={classes.toCodeforces} fontSize='inherit' /> </TextButton></Typography>}
                        />
                    </ListItem>
                </List>
        </Paper>
    )
}

export default ProfileCard;