import { Grid, makeStyles, Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom'
import TextButton from '../UI/TextButton';
import InputText from '../UI/InputText';
import InputLabel from '../UI/InputLabel';
import { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import useLocalStorage from '../../hooks/useLocalStorage';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: 0
    },
    loginButton: {
        width: '100%',
        height: '50px',
        textTransform: 'none',
        fontSize: '18px',
        marginBottom: '10px'
    },
    title: {
        fontWeight: 'bold'
    },
    registerLink: {
        textDecoration: 'none'
    }
}));

const LoginForm = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const setUser = useLocalStorage('user', null)[1];
    const [userError, setUserError] = useState(null);
    const [pwError, setPwError] = useState(null);
    const userRef = useRef();
    const pwRef = useRef();

    const onLogin = async (e) => {
        e.preventDefault();
        setUserError(null);
        setPwError(null);

        const username = userRef.current.value.trim();
        const password = pwRef.current.value.trim();
        if (username === '' || password === '') {
            if (username === '') {
                setUserError('Username cannot be empty');
            }
            if (password === '') {
                setPwError('Password cannot be empty')
            }
            return;
        }

        const fetchOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username,
                password
            }),
            credentials: 'include'
        }

        const response = await fetch(`/auth/login`, fetchOptions);
        if (response.status === 200) {
            const message = await response.json();
            setUser(message.body.user);
            history.push('/');
            return;
        }

        const message = await response.text();
        if (message === 'Unauthorized') {
            setUserError('Username/Password incorrect');
            setPwError('Username/Password incorrect');
            return;
        }
        if (message === 'Unverified') {
            history.push('/error', { message: "User inverified. Please wait for school/system admin to confirm your details and verify your registration." });
        }
        history.push('/error', { message });
    }

    return (
        <form className={classes.root} onSubmit={onLogin}>
            <Grid
                container
                direction="column"
                spacing={3}
                className={classes.root}
            >
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.title}>  Welcome back!  </Typography>
                    <Typography variant="h6" color='textSecondary'> We're excited to  see you again! </Typography>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel> Username </InputLabel>
                    <InputText id="username" fullWidth error={userError ? true : false} inputRef={userRef} helperText={userError} />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel> Password </InputLabel>
                    <InputText id="password" fullWidth type="password" error={pwError ? true : false} helperText={pwError} inputRef={pwRef} />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" className={classes.loginButton} type="submit">
                        Login
                    </Button>
                    <Typography color='textSecondary' disabled> Don't have an account? <Link to="/register" className={classes.registerLink}> <TextButton> Register </TextButton> </Link> </Typography>
                </Grid>
            </Grid>
        </form>
    )
}

export default LoginForm;