import * as JSOG from 'jsog';
import { useCallback, useState } from 'react';
import * as _ from 'underscore';
import { CircularProgress, Box, Typography, makeStyles } from '@material-ui/core';
import { Bracket, BracketGame } from 'react-tournament-bracket';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    root: {
        align: 'center',
        padding: theme.spacing(3),
        overflow: 'scroll',
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none'
    }
}));

function BracketCard(props) {
    const history = useHistory();
    const classes = useStyles();
    const [bracket, setBracket] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [hoveredContestant, setHoveredContestant] = useState(null);

    const changeHoveredContestant = (hoveredContestant) => setHoveredContestant(hoveredContestant);

    const GameComponent = useCallback(props => {
        return <BracketGame
            {...props}
            onHoveredTeamIdChange={changeHoveredContestant}
            hoveredTeamId={hoveredContestant}
            onClick={() => {
                history.push(`/match/${props.game.externalId}`)
            }}
        />
    }, [history, hoveredContestant]);

    useEffect(() => {
        setLoaded(false);
        if (props.bracket && props.bracketRoot) {
            setBracket(_.findWhere(JSOG.decode(props.bracket), { id: props.bracketRoot.toString() }));
            setLoaded(true);
        } else {
            setLoaded(true);
        }
    }, [props.bracket, props.bracketRoot]);

    if (!loaded) {
        return <Box align='center' p={3}> <CircularProgress size='5%' color='inherit' /> </Box>
    }

    return (
        <Box className={classes.root}>
            {bracket ?
                <Bracket game={bracket} GameComponent={GameComponent} homeOnTop={false} /> :
                <Typography> Bracket has not been generated </Typography>
            }
        </Box>
    )
}

export default BracketCard;