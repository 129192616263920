import { Paper, ListItem, ListItemIcon, ListItemText, Box, Grid, Typography } from '@material-ui/core';
import InputLabel from '../../UI/InputLabel';
import dateToString from '../../../utils/dateToString';
import RegisterIcon from '@material-ui/icons/AppRegistration';
import RegisterButton from './RegisterButton';


function RegistrationCard(props) {
    return (
        <Paper elevation={6} >
            <ListItem divider>
                <ListItemIcon>
                    <RegisterIcon />
                </ListItemIcon>
                <ListItemText size="xl" primary={<Typography variant='h6'> Registration </Typography>} />
            </ListItem>
            <Box p={2}>
                <Grid container direction='column' spacing={3}>
                    <Grid item>
                        <InputLabel> Registraion Period </InputLabel>
                        <Typography> {dateToString(props.regStartTime)} — {dateToString(props.regEndTime)} </Typography>
                    </Grid>
                    <Grid item>
                        <InputLabel> Registraion Status </InputLabel>
                        <Typography> {props.registerStatus} </Typography>
                    </Grid>
                    <Grid item>
                        <RegisterButton
                            registerStatus={props.registerStatus}
                            tournamentId={props.tournamentId}
                            getTournament={props.getTournament}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}

export default RegistrationCard;