import { useCallback, useEffect, useState } from 'react'
import { useMediaQuery, useTheme, Paper, Grid, Box, ListItem, ListItemIcon, ListItemText, Typography, Tab, Card, CardContent, IconButton, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import EditButton from '@material-ui/icons/Edit';
import ArrowBackButton from '@material-ui/icons/ArrowBack';
import ContestantsCard from '../components/tournament/register/ContestantsCard'
import RegistrationCard from '../components/tournament/register/RegistrationCard';
import BracketCard from '../components/tournament/main/BracketCard';
import MatchesCard from '../components/tournament/main/MatchesCard';
import ConfigCard from '../components/tournament/edit/ConfigCard';
import VerifyContestantsCard from '../components/tournament/edit/VerifyContestantCard';
import GenerateBracketCard from '../components/tournament/edit/GenerateBracketCard';
import { AccessLevelContext } from '../components/PrivateRoute';
import OverviewCard from '../components/tournament/OverviewCard';
import StyledTabs from '../components/UI/StyledTabs';
import { useParams, Route, Link, useHistory } from 'react-router-dom';
import { ACCESS_LEVEL_ENUM } from '../constants';



function TournamentPage(props) {
    const { tournamentId } = useParams();

    const [regStartTime, setRegStartTime] = useState(null);
    const [regEndTime, setRegEndTime] = useState(null);
    const [name, setName] = useState("");
    const [time, setTime] = useState(Date.now());
    const [bracket, setBracket] = useState(null);
    const [bracketRoot, setBracketRoot] = useState(null);
    const [contestants, setContestants] = useState([]);
    const [matches, setMatches] = useState(null);
    const [minDifficulty, setMinDifficulty] = useState(null);
    const [maxDifficulty, setMaxDifficulty] = useState(null);
    const [doubleElimination, setDoubleElimination] = useState(null);
    const [duration, setDuration] = useState(null);
    const [registerStatus, setRegisterStatus] = useState(null);
    const [errorMessages, setErrorMessages] = useState(null);

    const getTournament = useCallback(async () => {
        const response = await fetch(`/api/tournament/${tournamentId}`, { method: 'GET', credentials: 'include' });
        const tournament = (await response.json()).body;
        setName(tournament.name);
        setRegStartTime(tournament.regStartTime);
        setRegEndTime(tournament.regEndTime);
        setContestants(tournament.contestants);
        setBracket(tournament.bracket);
        setBracketRoot(tournament.bracketRoot);
        setMatches(tournament.matches);
        setRegisterStatus(tournament.registerStatus);
        setDuration(tournament.duration);
        setMinDifficulty(tournament.minDifficulty);
        setMaxDifficulty(tournament.maxDifficulty);
        setDoubleElimination(tournament.doubleElimination);

        // If error
        setErrorMessages(tournament.errorMessages);
    }, [tournamentId]);


    useEffect(() => {
        getTournament();
    }, [getTournament]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now());
        }, 500);
        return () => clearInterval(interval);
    }, []);

    if (errorMessages) return (
        <Card>
            <CardContent>
                <Typography variant='h5' gutterBottom>Error</Typography>
                {errorMessages.map(message => <Typography variant='h6'>{message}</Typography>)}
            </CardContent>
        </Card>
    );

    return (
        <Grid container direction='column' spacing={3}>
            <Grid item xs={12}>
                <TournamentTitle name={name} />
            </Grid>
            <Route exact path='/tournament/:tournamentId'>
                <Grid item xs={12}>
                    <OverviewCard
                        doubleElimination={doubleElimination}
                        minDifficulty={minDifficulty}
                        maxDifficulty={maxDifficulty}
                        duration={duration}
                    />
                </Grid>
                <Grid item xs={12}>
                    {time <= regEndTime ?
                        <Registration
                            regStartTime={regStartTime}
                            regEndTime={regEndTime}
                            contestants={contestants}
                            registerStatus={registerStatus}
                            tournamentId={tournamentId}
                            getTournament={getTournament} // refresh page
                        /> :
                        <TournamentDetail
                            bracket={bracket}
                            bracketRoot={bracketRoot}
                            matches={matches}
                        />}
                </Grid>
            </Route>
            <Route exact path='/tournament/:tournamentId/edit'>
                <Grid item xs={12}>
                    <EditDetails getTournament={getTournament} bracket={bracket} />
                </Grid>
            </Route>
        </Grid >
    )
}

function TournamentTitle(props) {
    const { tournamentId } = useParams();
    return (
        <Paper elevation={6}>
            <Box overflow='hidden'>
                <ListItem>
                    <ListItemIcon>
                        <TrophyIcon fontSize='large' />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant='h5'> {props.name} </Typography>} />
                    <AccessLevelContext.Consumer>
                        {accessLevel => accessLevel >= ACCESS_LEVEL_ENUM.SCHOOL_ADMIN &&
                            <>
                                <Route exact path='/tournament/:tournamentId'>
                                    <IconButton component={Link} to={`/tournament/${tournamentId}/edit`}>
                                        <EditButton />
                                    </IconButton>
                                </Route>
                                <Route exact path='/tournament/:tournamentId/edit'>
                                    <IconButton component={Link} to={`/tournament/${tournamentId}`}>
                                        <ArrowBackButton />
                                    </IconButton>
                                </Route>
                            </>
                        }
                    </AccessLevelContext.Consumer>
                </ListItem>
            </Box>
        </Paper>
    )
}

function Registration(props) {
    return (
        <Grid container direction='row' spacing={3}>
            <Grid item xs={12}>
                <RegistrationCard {...props} />
            </Grid>
            <Grid item xs={12}>
                <ContestantsCard contestants={props.contestants} />
            </Grid>
        </Grid>
    )
}


function TournamentDetail(props) {
    const [tabValue, setTabValue] = useState(0);
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const onTabChange = (event, newValue) => {
        setTabValue(newValue);
    }
    return (
        <Grid container direction='column' spacing={3}>
            <Grid item xs={12}>
                <Paper elevation={6}>
                    <StyledTabs
                        value={tabValue}
                        onChange={onTabChange}
                        scrollButtons="on"
                        variant={smDown ? 'fullWidth' : 'standard'}
                    >
                        <Tab label="Bracket" id={0} />
                        <Tab label="Matches" id={1} />
                    </StyledTabs>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                {tabValue === 0 && <BracketCard bracket={props.bracket} bracketRoot={props.bracketRoot} />}
                {tabValue === 1 && <MatchesCard matches={props.matches} />}
            </Grid>
        </Grid>
    )
}

function EditDetails(props) {
    const { tournamentId } = useParams();
    const [tabValue, setTabValue] = useState(0);
    const [config, setConfig] = useState(null);
    const history = useHistory();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));


    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successOpen, setSuccessOpen] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const getConfig = useCallback(async () => {
        fetch(`/api/tournament/${tournamentId}/config`, { credentials: 'include' })
            .then(response => {
                if (response.status !== 200) {
                    history.push('/error', { error: 'Page not found' });
                }
                return response.json();
            })
            .then(result => {
                setConfig(result.body)
            });
    }, [tournamentId, history]);

    useEffect(() => {
        getConfig();
    }, [getConfig]);

    const onTabChange = (event, newValue) => {
        setTabValue(newValue);
    }
    return (
        <>
            <Grid container direction='column' spacing={3}>
                <Grid item>
                    <Paper elevation={6}>
                        <AccessLevelContext.Consumer>
                            {accessLevel => accessLevel >= ACCESS_LEVEL_ENUM.SYSTEM_ADMIN ?
                                <StyledTabs
                                    value={tabValue}
                                    onChange={onTabChange}
                                    variant={smDown ? 'fullWidth' : 'standard'}
                                    scrollButtons="on"
                                >
                                    <Tab label="Config" />
                                    <Tab label="Contestants" />
                                    <Tab label="Bracket" />
                                </StyledTabs>
                                : <StyledTabs
                                    value={tabValue}
                                    onChange={onTabChange}
                                    variant={smDown ? 'fullWidth' : 'standard'}
                                    scrollButtons="on"
                                >
                                    <Tab label="Contestants" />
                                </StyledTabs>}
                        </AccessLevelContext.Consumer>
                    </Paper>
                </Grid>
                <Grid item>

                    <AccessLevelContext.Consumer>
                        {accessLevel => accessLevel >= ACCESS_LEVEL_ENUM.SYSTEM_ADMIN ?
                            <>
                                {tabValue === 0 &&
                                    <ConfigCard
                                        config={config}
                                        getTournament={props.getTournament}
                                        getConfig={getConfig}
                                        setErrorOpen={setErrorOpen}
                                        setErrorMessage={setErrorMessage}
                                        setSuccessOpen={setSuccessOpen}
                                        setSuccessMessage={setSuccessMessage}
                                    />
                                }
                                {tabValue === 1 &&
                                    <VerifyContestantsCard
                                        config={config}
                                        getTournament={props.getTournament}
                                        getConfig={getConfig}
                                        setErrorOpen={setErrorOpen}
                                        setErrorMessage={setErrorMessage}
                                        setSuccessOpen={setSuccessOpen}
                                        setSuccessMessage={setSuccessMessage}
                                    />
                                }
                                {tabValue === 2 &&
                                    <GenerateBracketCard
                                        bracket={props.bracket}
                                        getTournament={props.getTournament}
                                        getConfig={getConfig}
                                        setErrorOpen={setErrorOpen}
                                        setErrorMessage={setErrorMessage}
                                        setSuccessOpen={setSuccessOpen}
                                        setSuccessMessage={setSuccessMessage}
                                    />
                                }
                            </> : <>
                                {tabValue === 0 &&
                                    <VerifyContestantsCard
                                        config={config}
                                        getTournament={props.getTournament}
                                        getConfig={getConfig}
                                        setErrorOpen={setErrorOpen}
                                        setErrorMessage={setErrorMessage}
                                        setSuccessOpen={setSuccessOpen}
                                        setSuccessMessage={setSuccessMessage}
                                    />
                                }
                            </>
                        }
                    </AccessLevelContext.Consumer>
                </Grid>
            </Grid>
            <Snackbar open={errorOpen} autoHideDuration={2000} onClose={() => setErrorOpen(false)}>
                <Alert onClose={() => setErrorOpen(false)} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={2000} onClose={() => setSuccessOpen(false)}>
                <Alert onClose={() => setSuccessOpen(false)} severity="success">
                    {successMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default TournamentPage;