import { Link } from "react-router-dom";
import { Card, CardContent } from "@material-ui/core";
import styles from "./Scoreboard.module.scss";
import timeToString from '../../utils/timeToString';

function Scoreboard(props) {
    return (
        <div className={styles["scoreboard"]}>
            <div className={styles["board"]}>
                {
                    props.scoreboard?
                        props.scoreboard.map((task, index) => (
                            <a
                                key={index}
                                href={`https://codeforces.com/problemset/problem/${task.contestId}/${task.taskIndex}`}
                                target="_blank"
                                rel="noreferrer noopener"
                                className={(
                                    task.solvedUser===props.contestant1?
                                        styles["contestant1"]
                                    :(
                                        task.solvedUser===props.contestant2?
                                            styles["contestant2"]
                                        :(
                                            task.attempted?
                                                styles["attempted"]
                                            :
                                                ""
                                        )
                                    )
                                )}
                            >
                                <span>{task.contestId+task.taskIndex}</span>
                                <span>({task.difficulty})</span>
                            </a>
                        ))
                        :new Array(9).fill(null).map((_value, index) => <div key={index}></div>)
                }
            </div>
            <div className={styles["legend"]}>
                <div>
                    <div className={styles["contestant1"]} />
                    <Link to={`/profile/${props.contestant1}`} className={styles["user"]}>
                        {props.contestant1}{(props.winner!=null&&props.contestant1===props.winner)?" (Winner)":""}
                    </Link>
                </div>
                <div>
                    <div className={styles["contestant2"]} />
                    <Link to={`/profile/${props.contestant2}`} className={styles["user"]}>
                        {props.contestant2}{(props.winner!=null&&props.contestant2===props.winner)?" (Winner)":""}
                    </Link>
                </div>
                <div>
                    <MatchConfigTable config={props.config} />
                </div>
            </div>
        </div>
    );
}

function MatchConfigTable(props) {
    return (
        <Card>
            <CardContent>
                <table className={styles["config"]}>
                    <tbody>
                        <tr>
                            <td>Difficulty</td>
                            <td>{props.config.difficulty}</td>
                        </tr>
                        <tr>
                            <td>Start Time</td>
                            <td>{props.config.startTime?timeToString(props.config.startTime):"—"}</td>
                        </tr>
                        <tr>
                            <td>Duration</td>
                            <td>{props.config.duration} minutes</td>
                        </tr>
                    </tbody>
                </table>
            </CardContent>
        </Card>
    );
}

export default Scoreboard;
