import { useRef, useState } from 'react';
import { Paper, Box, Grid, Button, Typography, Divider, MenuItem } from '@material-ui/core';
import InputText from '../UI/InputText';
import InputLabel from '../UI/InputLabel';
import { useHistory } from 'react-router';

import stringToDate from '../../utils/stringToDate';

function CreateTournamentCard(props) {
    const history = useHistory();
    const nameRef = useRef();
    const regStartRef = useRef();
    const regEndRef = useRef();
    const minDiffRef = useRef();
    const maxDiffRef = useRef();
    const durationRef = useRef();
    const [errorText, setErrorText] = useState(null);
    const [doubleElimination, setDoubleElimination] = useState(false);

    const onChangeTournamentFormat = (event) => {
        setDoubleElimination(event.target.value);
    }

    const onCreateTournament = () => {
        setErrorText(null);
        const name = nameRef.current.value.trim();
        const regStartTime = regStartRef.current.value.trim();
        const regEndTime = regEndRef.current.value.trim();
        const minDifficulty = minDiffRef.current.value.trim();
        const maxDifficulty = maxDiffRef.current.value.trim();
        const duration = durationRef.current.value.trim();

        // check if everything is filled in
        if (name.length === 0 ||
            regStartTime.length === 0 ||
            regEndTime.length === 0 ||
            minDifficulty.length === 0 ||
            maxDifficulty.length === 0 ||
            duration.length === 0) {
            return setErrorText("Error: All fields must be filled");
        }

        if (!stringToDate(regStartTime)) {
            return setErrorText("Registration start time invalid");
        }
        if (!stringToDate(regEndTime)) {
            return setErrorText("Registration end time invalid");
        }
        if ((+minDifficulty).toString() !== minDifficulty || +minDifficulty < 0) {
            return setErrorText("Minimum difficulty invalid");
        }
        if ((+maxDifficulty).toString() !== maxDifficulty || +maxDifficulty < 0) {
            return setErrorText("Maximum difficulty invalid");
        }
        if (+minDifficulty > +maxDifficulty) {
            return setErrorText("Min difficulty should not be greater than max difficulty");
        }
        if ((+duration).toString() !== duration || duration < 0) {
            return setErrorText("Duration invalid");
        }

        fetch(`/api/tournament`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name,
                regStartTime: stringToDate(regStartTime),
                regEndTime: stringToDate(regEndTime),
                minDifficulty: +minDifficulty,
                maxDifficulty: +maxDifficulty,
                duration: +duration,
                doubleElimination,
            }),
            credentials: 'include',
        })
            .then(response => {
                return response.json();
            })
            .then(result => {
                if (result.status === "FAILED") {
                    return setErrorText(result.body.errorMessages.join('\n'));
                } else {
                    const tournamentId = result.body.id;
                    history.push(`/tournament/${tournamentId}`)
                }
            })
            .catch(err => {
                return setErrorText(err.toString());
            });
    }

    return (
        <Paper>
            <Box p={4}>
                <Box mb={3}>
                    <Typography variant='h6'> Tournament Config </Typography>
                    <Divider />
                </Box>
                <Grid container spacing={3}>
                    <AttrField label='Name' inputRef={nameRef} />
                    <AttrField label='Registration Start Time' inputRef={regStartRef} />
                    <AttrField label='Registration End Time' inputRef={regEndRef} />
                    <AttrField label='Min Difficulty' inputRef={minDiffRef} />
                    <AttrField label='Max Difficulty' inputRef={maxDiffRef} />
                    <AttrField label='Duration (in minutes)' inputRef={durationRef} />
                    <Grid item xs={12} md={4}>
                        <InputLabel> Tournament Format </InputLabel>
                        <InputText select fullWidth value={doubleElimination} onChange={onChangeTournamentFormat}>
                            <MenuItem value={false}> Single Elimination </MenuItem>
                            <MenuItem value={true}> Double Elimination </MenuItem>
                        </InputText>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={onCreateTournament}
                        > Create </Button>
                    </Grid>

                    {errorText &&
                        <Grid item xs={12}>
                            <Typography color='error'> {errorText} </Typography>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Paper>
    )
}

function AttrField(props) {
    return <Grid item xs={12} md={4}>
        <InputLabel> {props.label} </InputLabel>
        <InputText fullWidth inputRef={props.inputRef} />
    </Grid>
}

export default CreateTournamentCard;