import { useEffect, useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Grid, Card, CardHeader, CardContent, Typography, Box, Button } from '@material-ui/core';
import Scoreboard from '../components/match/Scoreboard';
import SubmissionTable, { SubmissionTableRow } from '../components/match/SubmissionTable';
import MatchTimer from '../components/match/MatchTimer';
import EnableNotificationsButton, { createNotification } from '../components/match/EnableNotificationsButton';
import EditIcon from '@material-ui/icons/Edit';

function MatchPage() {
    let match = useRouteMatch();
    const matchId = match.params.matchId;
    const [name, setName] = useState("");
    const [config, setConfig] = useState({
        duration: 0,
        difficulty: "0-0",
        scheduledStartTime: 0,
        startTime: null
    });
    const [contestant1, setContestant1] = useState("");
    const [contestant2, setContestant2] = useState("");
    const [winner, setWinner] = useState(null);
    const [submissions, setSubmissions] = useState([]);
    const [scoreboard, setScoreboard] = useState(null);
    const [editable, setEditable] = useState(false);
    const [errorMessages, setErrorMessages] = useState(null);
    const getMatch = useCallback(async () => {
        let match = await fetch(`/api/match-info/${matchId}`, {
            credentials: 'include'
        }).then(res => res.json());
        return match;
    }, [matchId]);
    const getMatchScoreboard = useCallback(async () => {
        let match = await fetch(`/api/match-scoreboard/${matchId}`, {
            credentials: 'include'
        }).then(res => res.json());
        return match;
    }, [matchId]);
    const reloadMatchScoreboard = useCallback(() => {
        getMatchScoreboard().then(match => {
            if (match.errorMessages) return;
            setWinner(match.winner);
            setSubmissions(match.submissions);
            setScoreboard(scoreboard => {
                if (scoreboard && match.scoreboard) scoreboard.forEach((task, index) => {
                    let { solvedUser, contestId, taskIndex } = match.scoreboard[index];
                    if (task.solvedUser !== solvedUser) {
                        createNotification('Task solved', {
                            body: `${solvedUser} solved ${contestId}${taskIndex}`,
                            icon: process.env.PUBLIC_URL + '/js-icon-sm-trans.ico'
                        });
                    }
                });
                return match.scoreboard;
            });
        });
    }, [getMatchScoreboard]);
    useEffect(() => {
        getMatch().then(match => {
            if (match.errorMessages) return setErrorMessages(match.errorMessages);
            else setErrorMessages(null);
            setName(match.name);
            setConfig({
                duration: match.duration,
                difficulty: match.difficulty,
                scheduledStartTime: match.scheduledStartTime,
                startTime: match.startTime
            });
            setContestant1(match.contestant1);
            setContestant2(match.contestant2);
            setEditable(match.editable);
        });
        reloadMatchScoreboard();
    }, [getMatch, reloadMatchScoreboard]);
    useEffect(() => {
        const interval = setInterval(() => {
            reloadMatchScoreboard();
        }, 5000);
        return () => {
            clearInterval(interval);
        }
    }, [reloadMatchScoreboard]);
    if (errorMessages) return (
        <Card>
            <CardHeader title='Error' />
            <CardContent>
                {errorMessages.map(message => <Typography variant='body1'>{message}</Typography>)}
            </CardContent>
        </Card>
    );
    return (
        <>
            <Box mb={3} display="flex" alignItems="center" flexWrap="wrap">
                <Box mr="auto">
                    <Box mr={2}>
                        <Typography variant="h4">{name}</Typography>
                    </Box>
                </Box>
                <MatchTimer winner={winner} {...config} />
                <EnableNotificationsButton ml={2} />
                {
                    editable &&
                    <Box ml={2}>
                        <Link to={`/edit-match/${matchId}`}>
                            <Button color="primary"><EditIcon /></Button>
                        </Link>
                    </Box>
                }
            </Box>
            <Grid container>
                <Grid item xs={12} xl={6}>
                    <Box mb={3}>
                        <Scoreboard contestant1={contestant1} contestant2={contestant2} winner={winner} scoreboard={scoreboard} config={config} />
                    </Box>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <Typography variant="h5" gutterBottom>Submissions</Typography>
                    <SubmissionTable>
                        {submissions.map((submission, index) => (
                            <SubmissionTableRow key={index} {...submission} />
                        ))}
                    </SubmissionTable>
                </Grid>
            </Grid>
        </>
    );
}

export default MatchPage;
