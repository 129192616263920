import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import dateToString from '../../utils/dateToString';

function timeDiff(endTime,currentTime) {
    let hours=Math.floor((endTime-currentTime)/(3600*1000));
    let minutes=Math.floor(((endTime-currentTime)%(3600*1000))/(60*1000));
    let seconds=Math.floor(((endTime-currentTime)%(60*1000))/1000);
    return `${hours}:${("0"+minutes).slice(-2)}:${("0"+seconds).slice(-2)}`;
}

function MatchTimer(props) {
    const [time, setTime] = useState(Date.now());
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now());
        }, 500);
        return () => clearInterval(interval);
    }, []);
    let endTime = props.startTime + props.duration * 60 * 1000;
    let timerText;
    if (props.startTime != null) {
        if (time < props.startTime) timerText = `Contest Starts in: ${timeDiff(props.startTime, time)}`;
        else if (props.winner || time > endTime) timerText = "Contest Ended";
        else timerText = `Time Remaining: ${timeDiff(endTime, time)}`;
    }
    else timerText = `Scheduled Start Time: ${dateToString(props.scheduledStartTime)}`;
    return (
        <Typography variant="h5">
            {timerText}
        </Typography>
    )
}

export default MatchTimer;
