import { useState, useRef, useCallback, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { Button, Snackbar, Grid, Paper, Box, List, ListItem, ListItemText, Typography, Divider } from '@material-ui/core';
import InputText from '../../components/UI/InputText';
import InputLabel from '../../components/UI/InputLabel';
import { Link } from 'react-router-dom';

const AdminProblemsPage = () => {

    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);

    const onError = (message) => {
        setErrorMessage(message);
        setErrorOpen(true);
    }

    const onSuccess = () => {
        setSuccessOpen(true);
    }

    const fetchProblems = async () => {
        const fetchOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }
        const response = await fetch(`/api/fetch-problems`, fetchOptions);
        if (response.status === 401) {
            onError("Unauthorized");
            return;
        } else {
            const res = response.json();
            if (res.status === "FAILED") {
                onError(res.body.errorMessages);
            } else {
                onSuccess();
            }
        }
    }

    const onFetch = () => {
        fetchProblems();
    }

    return (
        <>
            <Grid container spacing={3} direction='column'>
                <Grid item>
                    <Box component={Paper} p={2}>
                        <Button variant="contained" color="primary" onClick={onFetch}>Fetch Problems</Button>
                    </Box>
                </Grid>
                <Grid item>
                    <BannedProblemsCard
                        onSuccess={onSuccess}
                        onError={onError}
                    />
                </Grid>
            </Grid>
            <Snackbar open={errorOpen} autoHideDuration={2000} onClose={() => setErrorOpen(false)}>
                <Alert onClose={() => setErrorOpen(false)} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={2000} onClose={() => setSuccessOpen(false)}>
                <Alert onClose={() => setSuccessOpen(false)} severity="success">
                    Problems set successfully
                </Alert>
            </Snackbar>
        </>
    )
}

const BannedProblemsCard = ({ onError, onSuccess }) => {
    const contestIdRef = useRef();
    const taskIndexRef = useRef();
    const [bannedProblems, setBannedProblems] = useState([])

    const getBannedProblems = useCallback(async () => {
        const response = await fetch('/api/banned-problems', { credentials: 'include' });
        if (response.status !== 200) {
            onError('Unexpected Error')
            return [];
        }
        return (await response.json()).body.banned;
    }, [onError])

    const onBan = () => {
        const contestId = +contestIdRef.current.value.trim();
        const taskIndex = taskIndexRef.current.value.trim();
        if (contestId.length === 0 || taskIndex.length === 0)
            return onError('Contest Id and Task Id cannot be empty')
        fetch('/api/ban-problem', {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ contestId, taskIndex })
        })
            .then(response => {
                if (response.status !== 200)
                    return onError('Unexpected Error')
                return response.json();
            })
            .then(response => {
                if (response.status === "FAILED")
                    return onError(response.body.errorMessages);
                onSuccess()
            })
    };

    useEffect(() => {
        getBannedProblems().then(banned => {
            setBannedProblems(banned)
        })
    }, [getBannedProblems])

    return (
        <Box component={Paper} p={2}>
            <Box mb={3}>
                <Typography variant='h6'> Banned Problems </Typography>
                <Divider />
            </Box>
            <Box mb={3}>
                <InputLabel> Contest ID </InputLabel>
                <InputText inputRef={contestIdRef} />
            </Box>
            <Box mb={3}>
                <InputLabel> Task ID </InputLabel>
                <InputText inputRef={taskIndexRef} />
            </Box>
            <Button color='primary' variant='contained' onClick={onBan}> Ban Problem </Button>
            <List>
                {bannedProblems.map(problem => (
                    <ListItem
                        key={`${problem.contestId}${problem.taskIndex}`}
                        component={'a'}
                        href={`https://codeforces.com/contest/${problem.contestId}/problem/${problem.taskIndex}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        button
                        divider
                    >
                        <ListItemText>
                            {`${problem.contestId}${problem.taskIndex}`}
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}


export default AdminProblemsPage;