import { Tab, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import StyledTabs from '../UI/StyledTabs';
import { useRouteMatch } from 'react-router-dom';

const TeamsNavigation = () => {
    const match = useRouteMatch();
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const tabValue = match.params.tab ?? 'general';

    return (
        <Paper elevation={6}>
            <StyledTabs
                        value={tabValue}
                        variant={mdDown ? 'fullWidth' : 'standard'}
                    >
                <Tab component={Link} value='general' to='/teams' label='General' />
                <Tab component={Link} value='create' to='/teams/create' label='Create Team' />
                <Tab component={Link} value='join' to='/teams/join' label='Joined & Invited Teams' />
            </StyledTabs>
        </Paper>
    )
}

export default TeamsNavigation;
