import { Typography, Table, TableHead, TableRow, TableCell, TableBody, Paper, TableContainer, darken, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    tournamentRow: {
        '&:hover': {
            backgroundColor: darken(theme.palette.background.paper, 0.2)
        },
    },
    tournamentCell: { 
        display: 'contents',
        '&>a': {
            textDecoration: 'none',
        }
    }
}));

function TournamentsTable(props) {
    return (
        <TableContainer component={Paper} elevation={props.elevation ?? 6}>
            <Table>
                <TournamentsTableHead />
                <TableBody>
                    {props.tournaments.map(tournament => {
                        let status = "In progress...";
                        if (tournament.hasEnded) status = 'Ended';
                        else if (tournament.regStartTime <= Date.now() && Date.now() <= tournament.regEndTime) status = 'Open for registration';
                        else if (Date.now() <= tournament.regStartTime) status = 'Registration not open';
                        return <TournamentsTableRow key={tournament.id} id={tournament.id} name={tournament.name} status={status} winner={tournament.winner} hasEnded={tournament.hasEnded}/>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function TournamentsTableHead() {
    return (
        <TableHead>
            <TableRow>
                <TableCell align='center'> <Typography noWrap> ID </Typography></TableCell>
                <TableCell align='center'> <Typography noWrap> Name </Typography></TableCell>
                <TableCell align='center'> <Typography noWrap> Status </Typography></TableCell>
                <TableCell align='center'> <Typography noWrap> Winner </Typography></TableCell>
            </TableRow>
        </TableHead>
    )
}

function TournamentsTableRow(props) {
    const classes = useStyles();
    return (
        <TableRow className={classes.tournamentRow} >
            <TournamentsTableCell id={props.id}> {props.id} </TournamentsTableCell>
            <TournamentsTableCell id={props.id}> {props.name} </TournamentsTableCell>
            <TournamentsTableCell id={props.id}> {props.status} </TournamentsTableCell>
            <TournamentsTableCell id={props.id}> {props.hasEnded ? props.winner ?? "No winner" : ""} </TournamentsTableCell>
        </TableRow>
    )
}

function TournamentsTableCell(props) {
    const classes = useStyles();
    return (
        <td className={classes.tournamentCell}>
            <TableCell align='center' component={Link} to={`/tournament/${props.id}`}>
                <Typography noWrap> {props.children} </Typography>
            </TableCell>
        </td>
    );
}

export default TournamentsTable;
